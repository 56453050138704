import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logo from './mylogo.js';
import axios from "axios";
import { ConstantsService } from "../constants.service";
import * as XLSX from "xlsx";
@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor(public constants: ConstantsService,) {

  }

  async exportExcel(excelData) {

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;
    const description = excelData.description;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Registros');


    //Add Row and formatting


    if(header.length == 4){
      worksheet.mergeCells('A1', 'D4');
    }  else{worksheet.mergeCells('A1', 'E4');}
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // Date
    // worksheet.mergeCells('C1:D4');
    let d = new Date();
    let date = d.getDate().toString().padStart(2,"0") + '-' + (d.getMonth() + 1).toString().padStart(2,"0") + '-' + d.getFullYear();
    // let dateCell = worksheet.getCell('C1');
    // dateCell.value = date;
    // dateCell.font = {
    //   name: 'Calibri',
    //   size: 12,
    //   bold: true
    // }
    // dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Blank Row
    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);



      let sales = row.getCell(4);

      sales.alignment = { vertical: 'middle', horizontal: 'right' }
      let color = 'FF99FF99';
      if (+sales.value < 0) {
        color = 'FF9999'
      }

      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
      if(header.length == 5) {
        sales = row.getCell(5);
        sales.alignment = { vertical: 'middle', horizontal: 'right' }
        let color = 'FFD14B61';
        sales.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }

        sales.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      }
    }
    );
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 50;
    if(header.length == 5) {worksheet.getColumn(5).width = 10}
    worksheet.addRow([]);

    //Footer Row
    let footerRow = worksheet.addRow([description + ' ' + date]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB050' }
    };

    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:D${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then(async (data) => {

      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      // fs.saveAs(blob, title + '.xlsx');
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL, "_blank");
      // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    })
  }
}
