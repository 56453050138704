import { Component, OnInit, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Perfil } from "src/app/models/perfil";
const swal: SweetAlert = _swal as any;

import {
  NgbDateStruct,
  NgbTypeaheadConfig,
  NgbCalendar,
  NgbDate,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { isNumber, isDate } from "util";

import {
  debounceTime,
  distinctUntilChanged,
  map,
  filter,
} from "rxjs/operators";

import { element } from "protractor";
import { Pedido, DetallePedido } from "src/app/models/pedido";
import { Tienda } from "src/app/models/tienda";
import { Organizacion } from "src/app/models/organizacion";
import { DetalleOrden, Orden } from "src/app/models/orden";
import { Cliente } from "src/app/models/cliente";
import { ExportExcelService } from "src/app/services/export-excel.service";
import { isTemplateExpression } from "typescript";
import { data5col } from "src/app/models/exportexcel";

@Component({
  selector: "regventa",
  templateUrl: "./regventa.component.html",
  providers: [NgbTypeaheadConfig],
})
export class RegVentaComponent implements OnInit {
  public title: string;
  public rute: string;
  public _cantprod: number = 0;
  public precioventa: any = 0;
  public tipopago: number = 0;
  public pago: any = 0;
  public swpago: boolean = false;
  public _show: boolean = false;
  public _disabled: boolean = false;
  public _showedit: boolean = false;
  public _showeditdet: boolean = false;
  public _showeditdev: boolean = false;
  public _showdetalle: boolean = true;
  public _showdlista: boolean = true;
  public vr_imp: boolean = false;
  public stockprod: any
  // public _numlst_lg: string = "12";
  // public _numlst_md: string = "12";
  public _verpedido: boolean = false;
  public _model: any;
  public modelproducto: any;
  public modelcliente: any;
  public cantidadprod: any = 0;
  public fecha_Sel: String;
  model: NgbDateStruct;
  placement = "right";
  placement2 = "right";
  public fechaventa: NgbDate;
  public fechavta_rpt: NgbDate;
  public minFecha: NgbDate;
  public maxFecha: NgbDate;
  public proveddor_reg: Organizacion;
  public lstpedido: Array<Pedido>;
  public prodpedido_lst: Array<DetallePedido>;
  public pedido: Pedido;
  public orden_lst: Array<Orden>;
  public orden_fil: Array<Orden>;
  public closeResult = "";
  public cliente_reg: Cliente;
  public searchinput: string;
  public orden_reg: Orden;
  public detorden_lst: Array<DetalleOrden>;
  public deuda: any;
  public montocobro: any;
  public dinerodev: any = parseFloat("0").toFixed(2);
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    config: NgbTypeaheadConfig,
    private el: ElementRef,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.title = "Registro de ventas";
    this.rute = "tda/venta";
    this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
  }

  ngOnInit() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );

    this.fechaventa = this.calendar.getToday();
    this.fechavta_rpt = this.calendar.getToday();
    this.fechaactual(this.calendar.getToday());
    this.minFecha = this.calendar.getToday();
    this.maxFecha = this.calendar.getToday();
    this.lstpedido = new Array<Pedido>();
    this.prodpedido_lst = new Array<DetallePedido>();
    this.orden_lst = new Array<Orden>();
    this.orden_fil = new Array<Orden>();
    this.detorden_lst = new Array<DetalleOrden>();
    this._cantprod = 0;
    this.proveddor_reg = new Organizacion(
      0,
      "Seleccione...",
      "",
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      0,
      null,
      0
    );
    this.pedido = new Pedido(
      0,
      0,
      null,
      0,
      0,
      "",
      0,
      false,
      0,
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      "",
      "REGISTRANDO NUEVO PEDIDO",
      0, 0
    );
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    this.constants.listado_productos_vta(tienda_aux.tda_id);
    this.constants.listado_cliente(tienda_aux.tda_id);
    this.cliente_reg = new Cliente(
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      true,
      0,
      0,
      null,
      0,
      null
    );
    this.orden_reg = new Orden(
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      null,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      true,
      0,
      null,
      0,
      null,
      0,
      "",
      "",
      0,
      0
    );
    this.constants.listado_productos(0);
    this.listar();

    this.constants.obtener_datos_tda().then(()=>{
      console.log("TOPE FIADO: " + this.constants.datos_tda.tda_fiado)
      if(parseFloat(this.constants.datos_tda.tda_fiado) == 0){
        swal("Registro de ventas", "La tienda no tiene permiso para realizar ventas al crédito.", "info");
        // _ban = false;
        return false;
      }else{
        this.constants.obtener_montofiado_tda().then(()=>{
          console.log("MONTO FIADO: " + this.constants.monto_fiado)
          if(parseFloat(this.constants.datos_tda.tda_fiado) < parseFloat(this.constants.monto_fiado)){
            swal("Registro de ventas", "No puede realizar la venta al crédito porque ya llegó al tope del monto a fiar.", "info");
            // _ban = false;
            return false;
          }
        })
      }
    });
  }

  fechaactual(fecha_aux: NgbDate) {
    let fechas = fecha_aux;
    this.fecha_Sel =
      fechas.day.toString().padStart(2, "00") +
      "-" +
      fechas.month.toString().padStart(2, "00") +
      "-" +
      fechas.year.toString();
  }
  seleccionartipopago() {
    console.log(this.tipopago);
    switch (this.tipopago.toString()) {
      case "0":
        console.log(this.tipopago);
        this.swpago = true;
        this.pago = parseFloat("0.00").toFixed(2);
        break;
      case "1":
        console.log(this.tipopago);
        this.swpago = true;
        this.pago = parseFloat("0.00").toFixed(2);
        break;
      case "2":
        console.log(this.tipopago);
        this.swpago = false;
        this.pago = parseFloat("0.00").toFixed(2);
        break;
      case "3":
        console.log(this.tipopago);
        this.swpago = true;
        this.pago = this.getTotalCost();
        break;
    }
  }




  formatter = (producto: any) => producto.prod_descripcion;

  searchprod = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === ""
          ? []
          : this.constants.productosvta_lst
              .filter((producto) =>
                new RegExp(term, "mi").test(producto.prod_descripcion)
              )
              .slice(0, 10)
      )
    );

  formattercl = (cliente: any) => cliente.alias + ' - ' +  cliente.nombres + ' ' + cliente.apellidoPaterno + ' ' + cliente.apellidoMaterno;

  searchprodcl = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term2) =>
        term2 === ""
          ? []
          : this.constants.clientes_lst
              .filter((cliente) => new RegExp(term2, "mi").test(cliente.alias + cliente.nombres + cliente.apellidoPaterno + cliente.apellidoPaterno))
              .slice(0, 10)
      )
    );

  nuevo() {
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    this.pedido = new Pedido(
      0,
      0,
      null,
      0,
      0,
      "",
      0,
      false,
      0,
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      "",
      "REGISTRANDO NUEVO PEDIDO",
      0.0, 0
    );

    this._show = true;
    this._disabled = false;
    this._showedit = false;
    this._showeditdet = false;
    this._showeditdev = false;
    this._showdetalle = true;
    this._showdlista = true;
    this._verpedido = false;
    this._cantprod = 0;
    this.prodpedido_lst = new Array<DetallePedido>();
    this.fechaventa = this.calendar.getToday();
    this.modelcliente = null;
    this.tipopago = 3;
    this.seleccionartipopago();
    this.constants.listado_productos_vta(tienda_aux.tda_id)

  }

  editar() {
    this._showedit = true;
    this._showdlista = false;
    this._showdetalle = true;
  }

  eliminar(estado: boolean) {}

  ver_ventas() {
    this._showdlista = true;
    this._showdetalle = false;
    this._show = false;
    this._verpedido = false;
    this.searchinput = "";
    this.search_cliente("");
    this.fechavta_rpt = this.calendar.getToday();
    this.listarordenventa();
  }

  listar() {
    this._showdlista = true;
    this._showdetalle = false;
    this._show = false;
    this._verpedido = false;
    this.searchinput = "";
    this.search_cliente("");
    this.listarordenventa();
  }

  limpiar() {
    this._showdlista = true;
    this._showdetalle = false;
    this._show = false;
    this._verpedido = false;
    this.searchinput = "";
    this.search_cliente("");
    this.orden_lst = new Array<Orden>();
  }

  async eliminardetalle($detallepedido: DetallePedido) {
    let index: number = -1;
    let index2: number = -1;
    let idProv: number = 0;
    let c_prov: number = 0;
    console.log(JSON.stringify(this.prodpedido_lst));
    console.log($detallepedido);
    index = this.prodpedido_lst.indexOf($detallepedido);
    if (index > -1) {
      // this.constants.pedido_cart.splice(index, 1);
      this.prodpedido_lst.splice(index, 1);
    }
  }

  async registrar($state: boolean) {
    this.constants.obtener_montofiado_tda().then(()=>{
      this.constants.obtener_datos_tda().then(async ()=>{
//true:ingreso sin pedido - false: //desde pedido
let tienda_aux: Tienda = JSON.parse(
  localStorage.getItem("datostienda_tda")
);
let _ban: boolean = true;
if (this._showeditdet) {
  swal(
    "Registro de ventas",
    "EL detalle del pedido está edición, acepte los cambios.",
    "info"
  );
  _ban = false;
  return false;
}
console.log(this.modelcliente);



  if(this.modelcliente == null){
    swal(
      "Registro de ventas",
      "Seleccione nombre o alias de cliente sino registre uno nuevo.",
      "info"
    );
    _ban = false;
    return false;
  }else{
    if(this.modelcliente.id == 0 || this.modelcliente == ""){
      swal(
        "Registro de ventas",
        "Seleccione nombre o alias de cliente sino registre uno nuevo.",
        "info"
      );
      _ban = false;
      return false;
    }
  }

if (this.tipopago == 2 && this.pago <= 0.0) {
  swal("Registro de ventas", "Debe ingresar el pago a realizar.", "info");
  _ban = false;
  return false;
}

if(this.tipopago == 1 || this.tipopago == 2){
  console.log("TOPE FIADO: " + this.constants.datos_tda.tda_fiado)
  if(parseFloat(this.constants.datos_tda.tda_fiado) == 0){
    swal("Registro de ventas", "La tienda no tiene permiso para realizar ventas al crédito.", "info");
    _ban = false;
    return false;
  }else{

      console.log("MONTO FIADO: " + (parseFloat(this.constants.monto_fiado) + parseFloat(this.getTotalCost()) - parseFloat(this.pago)))
      if(parseFloat(this.constants.datos_tda.tda_fiado ) < (parseFloat(this.constants.monto_fiado) + parseFloat(this.getTotalCost())- parseFloat(this.pago)) ){
        swal("Registro de ventas", "No puede realizar la venta al crédito porque supera al tope del monto a fiar que es S/ " + parseFloat(this.constants.datos_tda.tda_fiado ).toFixed(2), "info");
        _ban = false;
        return false;
      }
  }
}




let detalleorden: Array<DetalleOrden>;
detalleorden = new Array<DetalleOrden>();

console.log(this.prodpedido_lst);
this.prodpedido_lst.forEach((item) => {
  if (item.preciovender == 0) {
    swal(
      "Registro de venta",
      "Ingrese precio de venta del producto " + item.producto,
      "info"
    );
    _ban = false;
    return false;
  }

  detalleorden.push(
    new DetalleOrden(
      0,
      0,
      item.idProducto,
      item.idProveedor,
      2,
      item.cantidadRecibida,
      0,
      item.precioUnit,
      item.precioTotal,
      item.preciovender,
      item.vigencia,
      item.producto,
      item.cantidadRecibida,
      0,
      item.precioTotal
    )
  );
});
console.log(this.modelcliente);
if (_ban) {
  let data = {
    id: 0,
    idProveedor: $state ? 0 : this.pedido.idProveedor,
    idCliente: this.modelcliente.id,
    idPedido: 0,
    fecha:
      this.fechaventa["year"] +
      "-" +
      this.fechaventa["month"] +
      "-" +
      this.fechaventa["day"],
    idVenta: 0,
    estado: 0,
    idTienda: tienda_aux.tda_id,
    tipoPago: this.tipopago,
    montopago: this.pago,
    tipoorden: 2, // 1: Ingreso compra, 2: Venta, 3: Devolución de cliente, 4: Devolución a proveedor
    total: this.getTotalCost(),
    subTotal: this.getSubTotalCost(),
    igv: this.getIgvCost(),
    detalleorden: detalleorden,
  };
  console.log("ORDEN DE INGRESO : " + JSON.stringify(data));
  this.spinner.show();
  await axios
    .post(this.constants.apiUrl + "registro_orden", data, {
      headers: this.constants.headers_aut,
    })
    .then((response) => {
      console.log(response.data);
      this.spinner.hide();
      if (response.data.estado) {
        swal("Registro Ingreso", response.data.mensaje, "success").then(
          () => {
            this.nuevo();
            this.ExportToPDF(response.data.codigo);
          }
        );

      } else {
        swal("Registro Ingreso", response.data.mensaje, "info");
      }
    })
    .catch(function (error) {
      this.spinner.hide();
      // swal("Ha ocurrido un error al registrar!", error.message, "error");
      console.log("tipo error " + error);
    });


}
      })
    })

  }

  cancelar(estado: boolean) {
    // true: registro / false:listado
    if (estado) {
      this._verpedido = true;
      this.verlista();
    } else {
      this.nuevo();
    }
  }

  verlista() {
    this._show = false;
    this._showdetalle = false;
    this.listarpedidos();
  }

  agregar() {
    let _ban = true;
    this.modelproducto.prod_cantidad = this.cantidadprod;
    this.prodpedido_lst.forEach((item) => {
      if (item.producto.trim() == this.modelproducto.prod_descripcion.trim()) {
        _ban = false;
      }
    });

    if(parseFloat(this.cantidadprod) > parseFloat(this.stockprod)){
      swal(
        "Registro de pedidos",
        "El stock del producto seleccionado es de "+ this.modelproducto.prod_stock + "unidad(es)",
        "info"
      ).then(()=>{return false;})
    }else
    if (_ban) {
      if (this.modelproducto.prod_cantidad <= 0) {
        swal(
          "Registro de pedidos",
          "Ingrese cantidad para " + this.modelproducto.prod_descripcion,
          "info"
        );
      } else if (this.precioventa <= 0) {
        swal(
          "Registro de pedidos",
          "Ingrese precio sugeridad que venderá el producto por unidad",
          "info"
        );
      // } else if() {

      } else{
        this.modelproducto.prod_preciototal = (
          this.modelproducto.prod_cantidad * this.modelproducto.prod_precio
        ).toFixed(2);

        this.prodpedido_lst.push(
          new DetallePedido(
            0,
            0,
            this.modelproducto.prod_id,
            parseFloat(this.modelproducto.prod_cantidad).toFixed(2),
            parseFloat(this.modelproducto.prod_cantidad).toFixed(2),
            parseFloat(this.modelproducto.prod_cantidad).toFixed(2),
            parseFloat(this.modelproducto.prod_cantidad).toFixed(2),
            0,
            parseFloat(this.modelproducto.prod_precio).toFixed(2),
            parseFloat(
              (this.modelproducto.prod_cantidad * this.precioventa).toString()
            ).toFixed(2),
            true,
            this.modelproducto.prod_descripcion,
            0,
            parseFloat(this.precioventa.toString()).toFixed(2),
            this.modelproducto.prod_idProveedor, this.modelproducto.prod_adminactualiza,
            parseFloat(this.modelproducto.prod_cantidad).toFixed(2)
          )
        );
        this.seleccionartipopago();
        // this.dataSource = this.prodpedido_lst;
      }
      if (this.prodpedido_lst.length > 0) {
        this._showedit = true;
      }
      this.modelproducto = null;
      this.cantidadprod = 0;
      this.precioventa = 0;
    } else {
      swal(
        "Registro de pedidos",
        "Producto " +
          this.modelproducto.prod_descripcion +
          " ya ha sido agregado.",
        "info"
      );
      this.modelproducto = null;
      this.cantidadprod = 0;
      this.precioventa = 0;
    }
    if (this.prodpedido_lst.length == 1) {
      this._cantprod = this.proveddor_reg.org_id;
    }
  }

  agregar_predido() {
    this._show = false;
    this._showdlista = false;
    this._showedit = true;
    this._verpedido = true;
    this.listarpedidos();
  }

  getTotalCost() {
    return this.prodpedido_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioTotal) == "number"
            ? sum + parseFloat(value.precioTotal)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalCost() {
    let total = this.getTotalCost();
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvCost() {
    let total = this.getTotalCost();
    return (
      parseFloat(this.getTotalCost()) - parseFloat(this.getSubTotalCost())
    ).toFixed(2);
  }

  getTotalVenta() {
    return this.orden_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.total) == "number"
            ? sum + (parseFloat(value.total) - parseFloat(value.montodevuelto))
            : sum,
        0
      )
      .toFixed(2);
  }

  getTotalOrd() {
    return this.detorden_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioTotal) == "number"
            ? sum + parseFloat(value.precioTotal)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalOrd() {
    let total = this.getTotalOrd();
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvOrd() {
    let total = this.getTotalOrd();
    return (
      parseFloat(this.getTotalOrd()) - parseFloat(this.getSubTotalOrd())
    ).toFixed(2);
  }

  getTotalDev() {
    return this.detorden_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioDevolver) == "number"
            ? sum + parseFloat(value.precioDevolver)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalDev() {
    let total = this.getTotalDev();
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvDev() {
    let total = this.getTotalDev();
    return (
      parseFloat(this.getTotalDev()) - parseFloat(this.getSubTotalDev())
    ).toFixed(2);
  }
  async listarpedidos() {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idtienda: tiendaaux.tda_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_pedidos_atendidos");
    this.lstpedido = new Array<Pedido>();
    await axios
      .post(this.constants.apiUrl + "lista_pedidos_atendidos", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let pedido_lst_aux = response.data.data;
        if (response.data.estado) {
          if (response.data.data != null || response.data.data != "null") {
            for (let element in pedido_lst_aux) {
              let pedido: Pedido = new Pedido(
                pedido_lst_aux[element].id,
                pedido_lst_aux[element].idProveedor,
                pedido_lst_aux[element].fechaPedido,
                pedido_lst_aux[element].idEmpresa,
                pedido_lst_aux[element].idTienda,
                pedido_lst_aux[element].direccion,
                pedido_lst_aux[element].estado,
                pedido_lst_aux[element].beneficio,
                parseFloat(pedido_lst_aux[element].total).toFixed(2),
                parseFloat(pedido_lst_aux[element].igv).toFixed(2),
                parseFloat(pedido_lst_aux[element].subtotal).toFixed(2),
                pedido_lst_aux[element].vigencia == 1 ? true : false,
                pedido_lst_aux[element].idUsuRegistro,
                pedido_lst_aux[element].fecharegistro,
                pedido_lst_aux[element].idProvActualiza,
                pedido_lst_aux[element].fechaactualiza,
                pedido_lst_aux[element].empresa,
                this.constants.estado_pedido[
                  parseInt(pedido_lst_aux[element].estado)
                ],
                parseFloat(pedido_lst_aux[element].precioAtendido).toFixed(2), 0
              );
              this.lstpedido.push(pedido);
            }
          }
        } else {
          swal("Registro de ventas ", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async ingresoproducto($pedido: Pedido) {
    this._verpedido = false;
    this.pedido = $pedido;
    this.listardetalle($pedido.id);
  }

  async listardetalle($idpedido: number) {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idpedido: $idpedido,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_detallepedido");
    await axios
      .post(this.constants.apiUrl + "lista_detallepedido", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let detalle_lst_aux = response.data.data;
        this.prodpedido_lst = new Array<DetallePedido>();
        if (response.data.estado) {
          for (let element in detalle_lst_aux) {
            this.prodpedido_lst.push(detalle_lst_aux[element]);
          }
        } else {
          swal("Detalle de pedido ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal(
          "Ha ocurrido un error al listar detalle de pedido!",
          error.message,
          "error"
        );
        console.log("tipo error " + error);
      });
  }

  edit_detail($state: boolean) {
    if (!$state) {
      this.prodpedido_lst.forEach((item) => {
        item.precioTotal = (item.cantidadRecibida * item.preciovender).toFixed(
          2
        );
      });
      this.seleccionartipopago();
    }
    this._showeditdet = $state;
  }

  async listarordenventa() {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idtienda: tiendaaux.tda_id,
      fecha:
        this.fechavta_rpt.year.toString() +
        "-" +
        this.fechavta_rpt.month.toString().padStart(2, "00") +
        "-" +
        this.fechavta_rpt.day.toString().padStart(2, "00"),
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_ordenventa");
    this.orden_lst = new Array<Orden>();
    this.orden_fil = new Array<Orden>();
    await axios
      .post(this.constants.apiUrl + "lista_ordenventa", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let pedido_lst_aux = response.data.data;
        if (response.data.estado) {
          if (response.data.data != null || response.data.data != "null") {
            this.orden_lst = response.data.data;
            this.orden_lst.forEach((item) => {
              item.deuda = parseFloat(item.deuda).toFixed(2);
              item.total = parseFloat(item.total).toFixed(2);
              item.montodevuelto = parseFloat(item.montodevuelto).toFixed(2);
              item.cliente = item.idCliente ==  0 ? 'OTROS' : item.cliente;
              if (item.total == item.montodevuelto) {
                item.tipoPago = 3;
              }
            });
            this.orden_fil = response.data.data;
          }
        }
        console.log(JSON.stringify(this.orden_lst))
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  verdetalle(content, $venta: Orden) {
    this.detorden_lst = new Array<DetalleOrden>();
    this.orden_reg = $venta;
    this.orden_reg.montopago = parseFloat($venta.montopago).toFixed(2);
    this.orden_reg.total = parseFloat($venta.total).toFixed(2);
    this.orden_reg.montodevuelto = parseFloat($venta.montodevuelto).toFixed(2);
    let totalamort: number =
      parseFloat($venta.montopago) + parseFloat($venta.montodevuelto);
    this.deuda = ($venta.total - totalamort).toFixed(2);
    this.listardetalleorden($venta.id);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  verdetalledevolver(content, $venta: Orden) {
    this.detorden_lst = new Array<DetalleOrden>();
    this._showeditdev = false;
    this.orden_reg = $venta;
    this.orden_reg.montopago = parseFloat($venta.montopago).toFixed(2);
    this.orden_reg.total = parseFloat($venta.total).toFixed(2);
    this.orden_reg.montodevuelto = parseFloat($venta.montodevuelto).toFixed(2);
    this.dinerodev = parseFloat("0").toFixed(2);
    let totalamort: number =
      parseFloat($venta.montopago) + parseFloat($venta.montodevuelto);
    this.deuda = ($venta.total - totalamort).toFixed(2);
    this.listardetalleorden($venta.id);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  vercobrar(content, $venta: Orden) {
    this.detorden_lst = new Array<DetalleOrden>();
    this.orden_reg = $venta;
    this.orden_reg.montopago = parseFloat($venta.montopago).toFixed(2);
    this.orden_reg.total = parseFloat($venta.total).toFixed(2);
    this.orden_reg.montodevuelto = parseFloat($venta.montodevuelto).toFixed(2);
    let totalamort: number =
      parseFloat($venta.montopago) + parseFloat($venta.montodevuelto);
    this.deuda = ($venta.total - totalamort).toFixed(2);
    this.montocobro = this.deuda;
    this.listardetalleorden($venta.id);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async registrar_cliente() {
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    if(this.cliente_reg.alias.trim() == ""){
      swal("Registro Cliente", "Ingrese alias para identificar a su cliente.", "info");
      return false;
    }
    let data = {
      id: this.cliente_reg.id,
      alias: this.cliente_reg.alias,
      nombres: this.cliente_reg.nombres,
      apellidopaterno: this.cliente_reg.apellidoPaterno,
      apellidomaterno: this.cliente_reg.apellidoMaterno,
      dni: this.cliente_reg.dni,
      ruc: this.cliente_reg.ruc,
      idtienda: tienda_aux.tda_id,
    };
    this.spinner.show();
    await axios
      .post(this.constants.apiUrl + "registra_cliente", data, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        if (response.data.estado) {
          swal("Registro Cliente", response.data.mensaje, "success").then(
            () => {
              this.modelcliente = null;

              this.constants.listado_cliente(tienda_aux.tda_id).then(() => {
                this.cliente_reg = new Cliente(
                  0,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  true,
                  0,
                  0,
                  null,
                  0,
                  null
                );
              });
            }
          );
          this.modalService.dismissAll();
        } else {
          swal("Registro Cliente", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        // swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  seleccionar_producto($prod: any) {
    this.cantidadprod = 1;
    this.stockprod = $prod.prod_stock
    this.precioventa = parseFloat($prod.prod_precio).toFixed(2);
  }

  search_cliente($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.orden_lst = new Array<Orden>();
    this.orden_lst = this.orden_fil.filter(
      (orden: Orden) => orden.cliente.toLocaleLowerCase().indexOf($txt) !== -1)
  }

  async listardetalleorden($idorden: number) {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idorden: $idorden,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_detalleorden");
    await axios
      .post(this.constants.apiUrl + "lista_detalleorden", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let detalle_lst_aux = response.data.data;
        this.detorden_lst = new Array<DetalleOrden>();
        if (response.data.estado) {
          this.detorden_lst = detalle_lst_aux;
          this.detorden_lst.forEach((item) => {
            item.cantidad = parseFloat(item.cantidad).toFixed(2);
            item.cantidaddev = parseFloat(item.cantidaddev).toFixed(2);
            item.cantidaddev_act = parseFloat(item.cantidaddev_act).toFixed(2);
            item.precioDevolver = parseFloat(item.precioDevolver).toFixed(2);
            item.precioUnit = parseFloat(item.precioUnit).toFixed(2);
            item.precioVentaUnid = parseFloat(item.precioVentaUnid).toFixed(2);
            item.precioTotal = parseFloat(item.precioTotal).toFixed(2);
          });
        } else {
          swal("Detalle de venta ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal(
          "Ha ocurrido un error al listar detalle de venta!",
          error.message,
          "error"
        );
        console.log("tipo error " + error);
      });
  }

  edit_devolucion($state: boolean) {
    let _ban = true;
    let _prod: string = "";
    if (!$state) {
      this.detorden_lst.forEach((item) => {
        if (item.cantidaddev_act > item.cantidad_act - item.cantidaddev) {
          _prod += item.producto + ", ";
          item.cantidaddev_act = item.cantidad_act - item.cantidaddev;
          _ban = false;
          return false;
        }
        item.cantidaddev_act = parseFloat(item.cantidaddev_act).toFixed(2);
        item.precioDevolver = (
          item.cantidaddev_act * item.precioVentaUnid
        ).toFixed(2);
      });
    }
    if (_ban) {
      if (!$state) {
        if (this.orden_reg.deuda > 0) {
          if (parseFloat(this.getTotalDev()) <= this.orden_reg.deuda) {
            if(this.orden_reg.deuda == this.orden_reg.total){
              this.dinerodev = 0.0
            }else{
              this.dinerodev = this.orden_reg.deuda - parseFloat(this.getTotalDev());
            }

          } else {
            let dev = parseFloat(this.getTotalDev()) - this.orden_reg.deuda;
            if (this.orden_reg.montopago <= 0) {
              this.dinerodev = 0.0;
            } else {
              if (dev > 0) {
                if (this.orden_reg.montopago > dev) {
                  this.dinerodev = dev;
                } else {
                  this.dinerodev = dev - this.orden_reg.montopago;
                }
              } else {
                this.dinerodev = 0.0;
              }
            }
          }
        } else {
          if (parseFloat(this.getTotalDev()) < this.orden_reg.montopago) {
            this.dinerodev = parseFloat(this.getTotalDev()).toFixed(2);
          } else {
            this.dinerodev = parseFloat(this.orden_reg.montopago).toFixed(2);
          }
        }
      }
      this.dinerodev = parseFloat(this.dinerodev).toFixed(2)
      this._showeditdev = $state;
    } else {
      swal(
        "Devolver Productos",
        "La cantidad a devolver supera a la cantidad vendida de los productos: " +
          _prod.substring(0, _prod.length - 2),
        "info"
      );
    }
  }

  async devolver($venta: Orden) {
    let valdev = parseFloat(this.getTotalDev());
    if(valdev <= 0){
      swal(
        "Devolver Productos",
        "La devolución no se realizó porque no se seleccionó productos",
        "info"
      );
      return false;
    }
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    let detalleorden: Array<DetalleOrden>;
    detalleorden = new Array<DetalleOrden>();
    this.detorden_lst.forEach((item) => {
      if (item.cantidaddev_act > 0) {
        detalleorden.push(
          new DetalleOrden(
            0,
            item.idOrden,
            item.idProducto,
            item.idProveedor,
            3,
            item.cantidaddev_act,
            item.cantidaddev,
            item.precioUnit,
            item.precioDevolver,
            item.precioVentaUnid,
            item.vigencia,
            item.producto,
            item.cantidad_act,
            item.cantidaddev_act,
            item.precioDevolver
          )
        );
      }
    });
    let data = {
      id: 0,
      idProveedor: 0,
      idCliente: $venta.idCliente,
      idPedido: 0,
      fecha:
        this.fechaventa["year"] +
        "-" +
        this.fechaventa["month"] +
        "-" +
        this.fechaventa["day"],
      idVenta: $venta.id,
      estado: 0,
      idTienda: tienda_aux.tda_id,
      tipoPago: 2,
      montopago: this.getTotalDev(),
      montodevuelto: this.dinerodev,
      tipoorden: 3, // 1: Ingreso compra, 2: Venta, 3: Devolución de cliente, 4: Devolución a proveedor
      total: this.getTotalDev(),
      subTotal: this.getSubTotalDev(),
      igv: this.getIgvDev(),
      detalleorden: detalleorden,
    };
    this.spinner.show();
    console.log("DEVOLUCIÓN: " + JSON.stringify(data));
    await axios
      .post(this.constants.apiUrl + "registro_orden", data, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        if (response.data.estado) {
          swal(
            "Devolución",
            "Se realizó la devolución de los productos.",
            "success"
          ).then(() => {
            this.listar();
          });
        } else {
          swal("Devolución", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        // swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async cobrar() {
    if (parseFloat(this.montocobro) > parseFloat(this.deuda)) {
      swal("Cobros", "El monto a cobrar es mayor a la deuda", "info");
    } else {
      let tienda_aux: Tienda = JSON.parse(
        localStorage.getItem("datostienda_tda")
      );
      let data = {
        idOrden: this.orden_reg.id,
        fecha:
          this.fechaventa["year"] +
          "-" +
          this.fechaventa["month"] +
          "-" +
          this.fechaventa["day"],
        tipo: true,
        idTienda: tienda_aux.tda_id,
        montopago: this.montocobro,
      };
      // this.spinner.show();
      console.log("COBRO: " + JSON.stringify(data));

      await axios
        .post(this.constants.apiUrl + "registro_cobropago", data, {
          headers: this.constants.headers_aut,
        })
        .then((response) => {
          console.log(response.data);
          this.spinner.hide();
          if (response.data.estado) {
            swal("Cobros", response.data.mensaje, "success").then(() => {
              this.listar();
              this.modalService.dismissAll();
            });
          } else {
            swal("Cobros", response.data.mensaje, "info");
          }
        })
        .catch(function (error) {
          this.spinner.hide();
          // swal("Ha ocurrido un error al registrar!", error.message, "error");
          console.log("tipo error " + error);
        });
    }
  }

  dataForExcel = [];
   empPerformance = []

  ExportToExcel()
  {
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table_exp.nativeElement);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // /* save to file */
    // XLSX.writeFile(wb, 'cierrecaja.xlsx');
    this.empPerformance = [];
    this.orden_lst.forEach((item) => {
      this.empPerformance.push({ "DEUDA":parseFloat(item.deuda).toFixed(2), "TIPO PAGO":this.constants.tipopago[item.tipoPago],"CLIENTE":item.cliente, "MONTO": parseFloat(item.total).toFixed(2), "DEVUELTO": parseFloat(item.montodevuelto).toFixed(2)})
    });
    this.empPerformance.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Ventas del día  ' + this.fechavta_rpt["day"].toString().padStart(2,"0") +
      "-" +
      this.fechavta_rpt["month"].toString().padStart(2,"0") +
      "-" +
      this.fechavta_rpt["year"],
      data: this.dataForExcel,
      headers: Object.keys(this.empPerformance[0]),
      description: "Reporte de ventas descargado el día"
    }

    this.ete.exportExcel(reportData);


  }

  async ExportExcel(){
    let data: data5col[] = []
    data.push({data1:'DEUDA',data2:'TIPO PAGO', data3:'CLIENTE', data4:'MONTO', data5:'DEVUELTO'})
    this.orden_lst.forEach((item) => {
      data.push({data1:parseFloat(item.deuda).toFixed(2), data2:this.constants.tipopago[item.tipoPago], data3:item.cliente, data4: parseFloat(item.total).toFixed(2), data5: parseFloat(item.montodevuelto).toFixed(2)})
    });
    let title= 'Ventas del día  ' + this.fechavta_rpt["day"].toString().padStart(2,"0") +
    "-" +
    this.fechavta_rpt["month"].toString().padStart(2,"0") +
    "-" +
    this.fechavta_rpt["year"];
    window.open(
      this.constants.apiUrl +
        "excel-reporte?data=" +
        JSON.stringify(data) + "&nombre="+title+"&col=5",
      "_blank"
    );
  }

  async ExportToPDF($id){



      window.open(
        this.constants.apiUrl +
          "descargar-venta?idOrden=" +
          $id,
        "_blank"
      );

  }

}
