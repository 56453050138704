import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Notificacion } from 'src/app/models/notificacion';
const swal: SweetAlert = _swal as any;
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
// import { ChartsModule, WavesModule } from 'angular-bootstrap-md';
import * as CanvasJS from '../../../../assets/scripts/canvasjs.min';
import { Tienda } from 'src/app/models/tienda';
import { Categoria } from "src/app/models/categoria";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Label } from "ng2-charts";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
@Component({
  selector: "estadisticas",
  templateUrl: "./regestadisticas.component.html",
})
export class EstadisticasComponent implements OnInit {
  public title: string;
  public rute: string;
  public closeResult = "";
  public categoria_lst: Array<Categoria>;
  public subcategoria_lst: Array<Categoria>;
  public categoriaproducto_lst: Array<Categoria>;
  public prod_idCategoria: number;
  public prod_superior: number;
  public  heightcd:any
  chartOptions = {
    responsive: true
  };
  chartData = [
    { data: [330, 600, 260, 700], label: 'Account A' },
    { data: [120, 455, 100, 340], label: 'Account B' },
    { data: [45, 67, 800, 500], label: 'Account C' }
  ];
  chartLabels = ['January', 'February', 'Mars', 'April'];
  titled = 'TOP 10 DE PRODUCTOS MÁS VENDIDOS';
  type='ColumnChart';
  data = [];

 columnNames = ['Descripción', 'Producto'];
 options = {
  colors: ['#e0440e'], is3D: true
};

title2 = 'TOP 10 DE PRODUCTOS MÁS VENDIDOS';
  type2='Table';
  data2 = [];

 columnNames2 = ['Código','Descripción', 'Producto'];
 options2 = {
  alternatingRowStyle:true,
     showRowNumber:true
};
width = 600;
   height = 500;


   public barChartOptions: ChartOptions = {
    responsive: true,

  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = []


  // Ventas devoluciones
  public barChartOptions2: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'end',
      }
    }
  };
  public barChartLabels2: Label[] = [];
  public barChartLegend2 = true;

  public barChartData2: ChartDataSets[] = [];

  public barChartLabels3: Label[] = [];

  public barChartData3: ChartDataSets[] = [];
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
  ) {
    this.title = "Estadísticas";
    this.rute = "tda/estadisticas";
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.categoria_lst = new Array<Categoria>();
    this.subcategoria_lst = new Array<Categoria>();
    this.categoriaproducto_lst = new Array<Categoria>();
    this.top_productos().then(()=>{
    this.constants.permisoconfig_existe(
      this.constants.usuario_tda.us_principal
    );
    this.constants.sesiontda(this.rute);
    this.constants.obtener_perfiles();

  });

  }

  ngOnInit() {
    // this.top_productos();
    this.subcategoria_lst.push(
      new Categoria(
        0,
        "",
        "TODOS",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );
    this.listado_categoria();
    this.listado_subcategoria();
    this.ventas_devoluciones();
    this.movimientos_caja();
      }


  async top_productos() {
    let dataPoints = [];
    let dataPoints2 = [];
    this.data = [];
    this.data2 = [];
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    this.barChartLabels = []
    this.barChartData = []
    let request = {
      idtienda: tiendaaux.tda_id,
      subcategoria: this.prod_idCategoria,
      categoria: this.prod_superior
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "top-producto", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("top producto \n" + JSON.stringify(response.data));

        if (response.data.estado) {

          if (response.data.data != null) {
            let product_aux = response.data.data;
            for (let element in product_aux) {
              //  console.log("num " + element);
              this.barChartLabels.push(product_aux[element].codigo)
              this.data.push(parseInt(product_aux[element].cantidad.toString()))
               dataPoints= [product_aux[element].codigo, parseInt(product_aux[element].cantidad.toString())];
               dataPoints2= [product_aux[element].codigo, product_aux[element].descripcion,  {v: parseInt(product_aux[element].cantidad.toString()), f: parseInt(product_aux[element].cantidad.toString())} ];
              // this.data.push(dataPoints)
              this.data2.push(dataPoints2)
            }

             this.barChartData=  [
              { data: this.data, label: 'Productos', borderColor: '#148c9f', backgroundColor: '#148c9f', hoverBackgroundColor: '#066F80'},
            ];
          }
        }
        this.spinner.hide()
      })
      .catch(function (error) {
        this.spinner.hide()
        console.log(error);
      });
  }

  async ventas_devoluciones() {
    this.barChartLabels2 = [];
    let data = [];
    let data2 = [];
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    this.barChartData2 = []
    let request = {
      idtienda: tiendaaux.tda_id
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "ventas-devol", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("ventas-devol \n" + JSON.stringify(response.data));

        if (response.data.estado) {

          if (response.data.data != null) {
            let product_aux = response.data.data;
            for (let element in product_aux) {
              if(product_aux[element].tipo == 2){
                data.push(parseInt(product_aux[element].cantidad.toString()))
                this.barChartLabels2.push(product_aux[element].fecha.toString())
              }else{
                data2.push(parseInt(product_aux[element].cantidad.toString()))
              }
            }

             this.barChartData2=  [
              { data: data, label: 'Ventas', borderColor: '#148c9f', backgroundColor: '#148c9f', hoverBackgroundColor: '#066F80'},
              { data: data2, label: 'Devoluciones', borderColor: '##F0CF4D', backgroundColor: '#F0CF4D', hoverBackgroundColor: '#e7ba05'},
            ];
          }
        }
        this.spinner.hide()
      })
      .catch(function (error) {
        this.spinner.hide()
        console.log(error);
      });
  }

  async movimientos_caja() {
    let data = [];
    let data2 = [];
    let data3 = [];
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    this.barChartData3 = []
    this.barChartLabels3 = []
    let request = {
      idtienda: tiendaaux.tda_id
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "mov-caja", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("mov-caja \n" + JSON.stringify(response.data));

        if (response.data.estado) {

          if (response.data.data != null) {
            let product_aux = response.data.data;
            for (let element in product_aux) {
              if(product_aux[element].tipo == 1){
                data.push(parseFloat(product_aux[element].cantidad.toString()).toFixed(2))
                this.barChartLabels3.push(product_aux[element].fecha.toString())
              }else if(product_aux[element].tipo == 0){
                data2.push(parseFloat(product_aux[element].cantidad.toString()).toFixed(2))
              }else{
                data3.push(parseFloat(product_aux[element].cantidad.toString()).toFixed(2))
              }
            }

             this.barChartData3=  [
              { data: data, label: 'Ingresos', borderColor: '#148c9f', backgroundColor: '#148c9f', hoverBackgroundColor: '#066F80'},
              { data: data2, label: 'Egresos', borderColor: '#F04D6B', backgroundColor: '#F04D6B', hoverBackgroundColor: '#DE3151'},
              { data: data3, label: 'Total', borderColor: '##F0CF4D', backgroundColor: '#F0CF4D', hoverBackgroundColor: '#e7ba05'},
            ];
          }
        }
        this.spinner.hide()
      })
      .catch(function (error) {
        this.spinner.hide()
        console.log(error);
      });
  }


  async listado_categoria() {
    this.categoria_lst = new Array<Categoria>();

    let request = {
      id: 4,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          this.categoria_lst.push(
            new Categoria(
              0,
              "",
              "TODOS",
              false,
              0,
              0,
              null,
              0,
              null,
              0,
              "",
              ""
            )
          );
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              this.categoria_lst.push(categoria_aux);
            }
          }
          this.prod_idCategoria = 0;
          this.prod_superior = 0;
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async listado_subcategoria() {
    this.categoria_lst = new Array<Categoria>();

    let request = {
      id: 5,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              this.categoriaproducto_lst.push(categoria_aux);
            }
          }
          console.log("PRODUCTOS " + JSON.stringify(this.categoriaproducto_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  seleccionar_superior($id: number, $ban: boolean) {
    let categoria_aux: Categoria;
    this.subcategoria_lst = new Array<Categoria>();
    this.subcategoria_lst.push(
      new Categoria(
        0,
        "",
        "TODOS",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );

    if ($id > 0) {
      console.log("SUPERIOR " + $id);
      this.categoriaproducto_lst.forEach((item, index) => {
        if (item.cat_superior == $id) {
          categoria_aux = new Categoria(
            item.cat_id,
            item.cat_codigo,
            item.cat_descripcion,
            item.cat_vigencia,
            item.cat_superior,
            item.cat_idCategoria,
            null,
            0,
            null,
            0,
            "",
            ""
          );
          this.subcategoria_lst.push(categoria_aux);
        }
      });
    }
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  filtrar(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  // download(){}

  // tslint:disable-next-line:typedef
  download($tipo:number) {
    this.spinner.show()
    // Extraemos el
    const DATA = document.getElementById('htmlData');
    const DATA2 = document.getElementById('htmlData2');
    const DATA3 = document.getElementById('htmlData3');
    const DATA4 = document.getElementById('htmlData4');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };

    if($tipo == 0){
      html2canvas(DATA, options).then((canvas) => {

        const img = canvas.toDataURL('image/PNG');

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        this.heightcd = pdfHeight;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        return doc;
      }).then((docResult) => {
        html2canvas(DATA2, options).then((canvas) => {

          const img = canvas.toDataURL('image/PNG');

          // Add image Canvas to PDF
          const bufferX = 15;
          const bufferY = this.heightcd + 50;
          const imgProps = (doc as any).getImageProperties(img);
          const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
          return doc;
        }).then((docResult) => {
          //  window.open(docResult.output('bloburl', {filename: `${new Date().toISOString()}_top10productos.pdf`}));
          docResult.save(`${new Date().toISOString()}_top10productos.pdf`);
          this.spinner.hide()
        });
      });
    }else if($tipo == 1){
      html2canvas(DATA3, options).then((canvas) => {

        const img = canvas.toDataURL('image/PNG');

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        this.heightcd = pdfHeight;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        return doc;
      }).then((docResult) => {
        // window.open(docResult.output('bloburl', {filename: `${new Date().toISOString()}_ventadevoluciones.pdf`}));
          docResult.save(`${new Date().toISOString()}_ventadevoluciones.pdf`);
          this.spinner.hide()
      });
    }else if($tipo == 2){
      html2canvas(DATA4, options).then((canvas) => {

        const img = canvas.toDataURL('image/PNG');

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        this.heightcd = pdfHeight;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        return doc;
      }).then((docResult) => {
        // window.open(docResult.output('bloburl', {filename: `${new Date().toISOString()}_movimientoscaja.pdf`}));
          docResult.save(`${new Date().toISOString()}_movimientoscaja.pdf`);
          this.spinner.hide()
      });
    }
  }

  async listar(){
    this.spinner.show()
    this.top_productos();
    this.ventas_devoluciones()
    this.movimientos_caja()

  }

}
