// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
          production: false,
          URLAPI:  "https://backcaserita.gruva.net/api/v1/",//"http://192.168.0.2:8000/api/v1/",//
          INDEX: "https://caserita.gruva.net/",
          INDEXTDA: "https://caseritatda.gruva.net/",
          SITEKEY: "6Lc2Of8UAAAAAHTO1VB96KHQNOk8GgPzTMgGuoqw",
       };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
