import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Notificacion } from 'src/app/models/notificacion';
const swal: SweetAlert = _swal as any;
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "bandeja",
  templateUrl: "./bandeja.component.html",
})
export class BandejaComponent implements OnInit {
  public title: string;
  public rute: string;
  public notificacion_lst: Array<Notificacion>;
  public notificacion_fil: Array<Notificacion>;
  public searchinput: string;
  public not_v: Notificacion;
  public closeResult = "";
  public tipo: number = 0;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {
    this.title = "Mensajería";
    this.rute = "tda/bandeja";
    this.notificacion_lst = new Array<Notificacion>();
    this.notificacion_fil = new Array<Notificacion>();
    this.not_v = new Notificacion(0,null,"","",0,"","");
    this.searchinput = "";
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.constants.permisoconfig_existe(
      this.constants.usuario_tda.us_principal
    );
    this.constants.sesiontda(this.rute);
    this.constants.obtener_perfiles();
  }

  ngOnInit() {
    this.spinner.show();
    this.listado_notificacion();
  }

  search_notificacion($txt: string) {
    // $txt = $txt.toLocaleLowerCase();
    // this.notificacion_lst = new Array<Notificacion>();
    // this.notificacion_lst = this.notificacion_fil.filter(
    //   (notificacion: Notificacion) =>
    //     notificacion.asunto.toLocaleLowerCase().indexOf($txt) !== -1
    // );
    console.log($txt)
    $txt = $txt.toLocaleLowerCase();
    this.notificacion_lst = new Array<Notificacion>();
    if (this.tipo == 0) {
      this.notificacion_lst = this.notificacion_fil.filter(
        (notificacion: Notificacion) =>
          notificacion.asunto.toLocaleLowerCase().indexOf($txt) !== -1
      );
    } else if (this.tipo == 1) {
      this.notificacion_lst = this.notificacion_fil.filter(
        (notificacion: Notificacion) =>
          notificacion.empresa.toLocaleLowerCase().indexOf($txt) !== -1 ||  notificacion.tipo.toLocaleLowerCase().indexOf($txt) !== -1
      );
    }
  }

  async listado_notificacion() {
    this.spinner.show();
    this.notificacion_lst = new Array<Notificacion>();

    let request = {};
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "notificaciones-app", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log("notifiaciones \n" + JSON.stringify(response.data));
        this.spinner.hide();

        if (response.data.estado) {
          if (response.data.data != null) {
            let notificacion_lst_aux = response.data.data;
            for (let element in notificacion_lst_aux) {
              // console.log("num " + element);
              notificacion_lst_aux[element].empresa = notificacion_lst_aux[element].empresa == null ? '':  notificacion_lst_aux[element].empresa.toString()
              this.notificacion_lst.push(notificacion_lst_aux[element]);
              this.notificacion_fil = this.notificacion_lst;
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
        // else {
        //   swal("Notificaciones", response.data.mensaje, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  vermensaje(content,  not) {
    this.not_v = not;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
}
