import { Component, OnInit, ElementRef, ViewChild  } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Perfil } from "src/app/models/perfil";
import { Caja } from "src/app/models/caja";
import { Tienda } from "src/app/models/tienda";
import * as XLSX from 'xlsx';

const swal: SweetAlert = _swal as any;
import {
  NgbDateStruct,
  NgbTypeaheadConfig,
  NgbCalendar,
  NgbDate,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { Categoria } from "src/app/models/categoria";
import { ExportExcelService } from "src/app/services/export-excel.service";
import { data4col } from "src/app/models/exportexcel";
@Component({
  selector: "regcierrecaja",
  templateUrl: "./regcierrecaja.component.html",
  styleUrls: ["./regcierrecaja.component.css"],
})
export class CierreCajaComponent implements OnInit {
  @ViewChild('table_exp', null) table_exp: ElementRef;
  public title: string;
  public rute: string;
  public closeResult = "";
  public caja_lst: Array<Caja>;
  public caja_reg: Caja;
  model: NgbDateStruct;
  placement = "right";
  public fechacaja: NgbDate;
  public minFecha: NgbDate;
  public maxFecha: NgbDate;
  public tipomov: number = -1;
  public categoria_lst: Array<Categoria>;
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    config: NgbTypeaheadConfig,
    private el: ElementRef,
    private calendar: NgbCalendar,
    public modalService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.title = "Ingresos y gastos";
    this.rute = "tda/cierrecaja";
    this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
  }

  ngOnInit() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.fechacaja = this.calendar.getToday();
    this.maxFecha = this.calendar.getToday();
    this.caja_lst = new Array<Caja>();
    this.caja_reg = new Caja(
      0,
      false,
      0,
      0,
      "",
      0,
      0,
      false,
      false,
      0,
      null,
      0,
      null,
      "", ""
    );
    this.listar_caja_mov();
    this.listado_categoria();
    this.tipomov = -1;
  }

  async cierrecaja(){
    swal({
      title: "Cierre Caja",
      text: "Está cerrando la caja del día " + this.fechacaja["day"] +
      "-" +
      this.fechacaja["month"] +
      "-" +
      this.fechacaja["year"] + ". ¿Está seguro de está elección?",
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then(async (willDelete) => {
      if(willDelete){
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idtienda: tiendaaux.tda_id,
      idusu: this.constants.usuario_tda.us_id,
      token: this.constants.usuario_tda.us_token,
      monto: this.getTotalCaja(),
      fecha:
        this.fechacaja["year"] +
        "-" +
        this.fechacaja["month"] +
        "-" +
        this.fechacaja["day"],
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "cerrar-caja", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("Canje \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          swal("Registro de caja", response.data.mensaje.toString(), "success");
        }else{
          swal("Registro de caja", response.data.mensaje.toString(), "info");
        }
        this.listar_caja_mov();
        this.spinner.hide();

      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
    }
    });
  }

  dataForExcel = [];
   empPerformance = []

  ExportToExcel()
    {
      // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table_exp.nativeElement);
      // const wb: XLSX.WorkBook = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // /* save to file */
      // XLSX.writeFile(wb, 'cierrecaja.xlsx');
      this.empPerformance = [];
      this.caja_lst.forEach((item) => {
        this.empPerformance.push({"TIPO":item.tipomov, "MOTIVO":item.motivo, "GLOSA":item.glosa, "MONTO": parseFloat(item.monto).toFixed(2)})
      });
      this.empPerformance.forEach((row: any) => {
        this.dataForExcel.push(Object.values(row))
      })

      let reportData = {
        title: 'Movimiento de Caja :: ' + this.fechacaja["day"].toString().padStart(2,"0") +
        "-" +
        this.fechacaja["month"].toString().padStart(2,"0") +
        "-" +
        this.fechacaja["year"],
        data: this.dataForExcel,
        headers: Object.keys(this.empPerformance[0]),
        description: "Reporte de movimiento de caja descargado el día"
      }

      this.ete.exportExcel(reportData);


    }

    async ExportExcel(){
      let data: data4col[] = []
      data.push({data1:'TIPO',data2:'MOTIVO', data3:'GLOSA', data4:'MONTO'})
      this.caja_lst.forEach((item) => {
        data.push({data1:item.tipomov, data2:item.motivo, data3:item.glosa == null ? '-' : item.glosa, data4: parseFloat(item.monto).toFixed(2)})
      });
      let title= 'Movimiento de Caja :: ' + this.fechacaja["day"].toString().padStart(2,"0") +
        "-" +
        this.fechacaja["month"].toString().padStart(2,"0") +
        "-" +
        this.fechacaja["year"];
      window.open(
        this.constants.apiUrl +
          "excel-reporte?data=" +
          JSON.stringify(data) + "&nombre="+title+"&col=4",
        "_blank"
      );
    }

  async listar_caja_mov() {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idtienda: tiendaaux.tda_id,
      idusu: this.constants.usuario_tda.us_id,
      token: this.constants.usuario_tda.us_token,
      fecha:
        this.fechacaja["year"] +
        "-" +
        this.fechacaja["month"] +
        "-" +
        this.fechacaja["day"],
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_caja_diario");
    await axios
      .post(this.constants.apiUrl + "lista_caja_diario", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let caja_lst_aux = response.data.data;
        this.caja_lst = new Array<Caja>();
        if (response.data.estado) {
          for (let element in caja_lst_aux) {
            this.caja_lst.push(caja_lst_aux[element]);
          }
        } else {
          swal("Caja Diario ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  getTotalCaja() {
    let monto: any = this.caja_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.monto) == "number"
            ? sum + parseFloat(value.monto)
            : sum,
        0
      )
      .toFixed(2);
    // if (monto <= 0) {
    //   monto = 0.0;
    // }
    return parseFloat(monto).toFixed(2);
  }

  limpiar(){
    this.caja_reg = new Caja(
      0,
      false,
      0,
      0,
      "",
      0,
      0,
      false,
      false,
      0,
      null,
      0,
      null,
      "", ""
    );
  }

  open(content) {
  this.limpiar();
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async registrarmovimiento() {
    console.log(JSON.stringify(this.categoria_lst.filter(categoria => categoria.cat_id == this.tipomov)[0].cat_descripcion));
    if (this.tipomov == -1) {
      swal("Registro de movimiento", "Seleccione tipo de movimiento", "info");
    } else if (this.caja_reg.monto == 0) {
      swal("Registro de movimiento", "Ingrese monto", "info");
    } else if (
      this.caja_reg.glosa == null ||
      this.caja_reg.glosa.trim() == ""
    ) {
      swal("Registro de movimiento", "Ingrese motivo", "info");
    } else {
      let tienda_aux: Tienda = JSON.parse(
        localStorage.getItem("datostienda_tda")
      );
      let data = {
        monto: this.caja_reg.monto,
        tipo: this.tipomov == 0 ? true : false,
        motivo: this.categoria_lst.filter(categoria => categoria.cat_id == this.tipomov)[0].cat_descripcion,
        idTienda: tienda_aux.tda_id,
        glosa: this.caja_reg.glosa,
      };
      // this.spinner.show();
      console.log("REG MOVIMIENTO: " + JSON.stringify(data));

      await axios
        .post(this.constants.apiUrl + "registro_movimiento", data, {
          headers: this.constants.headers_aut,
        })
        .then((response) => {
          console.log(response.data);
          this.spinner.hide();
          if (response.data.estado) {
            swal(
              "Registro de movimiento",
              response.data.mensaje,
              "success"
            ).then(() => {
              this.listar_caja_mov();
              this.limpiar();
              this.modalService.dismissAll();
            });
          } else {
            swal("Registro de movimiento", response.data.mensaje, "info");
          }
        })
        .catch(function (error) {
          this.spinner.hide();
          // swal("Ha ocurrido un error al registrar!", error.message, "error");
          console.log("tipo error " + error);
        });
    }
  }


  async listado_categoria() {
    this.categoria_lst = new Array<Categoria>();

    let request = {
      id: 8,
      superior: 0,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "list_categoria", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log("categoria \n" + JSON.stringify(response.data));

        if (response.data.estado) {
          this.categoria_lst.push(
            new Categoria(
              -1,
              "",
              "SELECCIONE...",
              false,
              0,
              0,
              null,
              0,
              null,
              0,
              "",
              ""
            )
          );

          this.categoria_lst.push(
            new Categoria(
              0,
              "",
              "OTROS INGRESOS",
              false,
              0,
              0,
              null,
              0,
              null,
              0,
              "",
              ""
            )
          );
          if (response.data.data != null) {
            let categoria_lst_aux = response.data.data;
            let categoria_aux: Categoria;
            for (let element in categoria_lst_aux) {
              // console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                categoria_lst_aux[element].fecharegistro,
                categoria_lst_aux[element].adminregistro,
                categoria_lst_aux[element].fechactualiza,
                categoria_lst_aux[element].adminactualiza,
                categoria_lst_aux[element].categoria,
                categoria_lst_aux[element].superiordesc
              );
              this.categoria_lst.push(categoria_aux);
            }
          }
          // console.log("PRODUCTOS " + JSON.stringify(this.productos_lst));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
