import { Component, OnInit, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Perfil } from "src/app/models/perfil";
const swal: SweetAlert = _swal as any;

import {
  NgbDateStruct,
  NgbTypeaheadConfig,
  NgbCalendar,
  NgbDate,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { isNumber, isDate } from "util";

import {
  debounceTime,
  distinctUntilChanged,
  map,
  filter,
} from "rxjs/operators";

import { element } from "protractor";
import { Pedido, DetallePedido } from "src/app/models/pedido";
import { Tienda } from "src/app/models/tienda";
import { Organizacion } from "src/app/models/organizacion";
import { DetalleOrden, Orden } from "src/app/models/orden";
import { ExportExcelService } from "src/app/services/export-excel.service";
import { data4col } from "src/app/models/exportexcel";

@Component({
  selector: "regingresoprod",
  templateUrl: "./regingresoprod.component.html",
  providers: [NgbTypeaheadConfig],
})
export class IngresoProdComponent implements OnInit {
  public title: string;
  public rute: string;
  public _cantprod: number = 0;
  public precioventa: number = 0;
  public tipopago: number = 0;
  public pago: any = 0;
  public swpago: boolean = false;
  public _show: boolean = true;
  public _disabled: boolean = false;
  public _showedit: boolean = false;
  public _showeditdet: boolean = false;
  public _showdetalle: boolean = true;
  public _showdlista: boolean = true;
  // public _numlst_lg: string = "12";
  // public _numlst_md: string = "12";
  public _verpedido: boolean = false;
  public _model: any;
  public _comstants_producto: any;
  public modelproducto: any;
  public cantidadprod: any = 0;

  model: NgbDateStruct;
  placement = "left";
  public fechaventa: NgbDate;
  public minFecha: NgbDate;
  public maxFecha: NgbDate;

  public proveedor_lst: Array<Organizacion>;
  public proveddor_reg: Organizacion;
  public lstpedido: Array<Pedido>;
  public prodpedido_lst: Array<DetallePedido>;
  public pedido: Pedido;
  public orden_lst: Array<Orden>;
  public closeResult = "";
  public orden_reg: Orden;
  public detorden_lst: Array<DetalleOrden>;
  public deuda: any;
  public montocobro: any;

  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    config: NgbTypeaheadConfig,
    private el: ElementRef,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.title = "Ingreso de productos";
    this.rute = "tda/ingresoproduct";
    this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
  }

  ngOnInit() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );

    this.fechaventa = this.calendar.getToday();
    this.minFecha = this.calendar.getToday();
    this.maxFecha = this.calendar.getToday();
    this.lstpedido = new Array<Pedido>();
    this.proveedor_lst = new Array<Organizacion>();
    this.prodpedido_lst = new Array<DetallePedido>();
    this.orden_lst = new Array<Orden>();
    this._cantprod = 0;
    this.proveddor_reg = new Organizacion(
      0,
      "Seleccione...",
      "",
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      0,
      null,
      0
    );
    this.pedido = new Pedido(
      0,
      0,
      null,
      0,
      0,
      "",
      0,
      false,
      0,
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      "",
      "REGISTRANDO NUEVO PEDIDO",
      0, 0
    );
    this.listar_proveedor();
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    this.constants.listado_productos_tda(tienda_aux.tda_id);
    this.detorden_lst = new Array<DetalleOrden>();
    this.orden_reg = new Orden(
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      null,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      true,
      0,
      null,
      0,
      null,
      0,
      "",
      "",
      0,
      0
    );
    this.tipopago = 3;
    this.seleccionartipopago();
  }
  async listardetalleorden($idorden: number) {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idorden: $idorden,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_detalleorden");
    await axios
      .post(this.constants.apiUrl + "lista_detalleorden", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let detalle_lst_aux = response.data.data;
        this.detorden_lst = new Array<DetalleOrden>();
        if (response.data.estado) {
          this.detorden_lst = detalle_lst_aux;
          this.detorden_lst.forEach((item) => {
            item.cantidad = parseFloat(item.cantidad).toFixed(2);
            item.cantidad_act = parseFloat(item.cantidad_act).toFixed(2);
            item.cantidaddev = parseFloat(item.cantidaddev).toFixed(2);
            item.cantidaddev_act = parseFloat(item.cantidaddev_act).toFixed(2);
            item.precioDevolver = parseFloat(item.precioDevolver).toFixed(2);
            item.precioTotal = parseFloat(item.precioTotal).toFixed(2);
            item.precioUnit = parseFloat(item.precioUnit).toFixed(2);
            item.precioVentaUnid = parseFloat(item.precioVentaUnid).toFixed(2);
          });
        } else {
          swal("Detalle de venta ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal(
          "Ha ocurrido un error al listar detalle de venta!",
          error.message,
          "error"
        );
        console.log("tipo error " + error);
      });
  }
  getTotalOrd() {
    return this.detorden_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioTotal) == "number"
            ? sum + parseFloat(value.precioTotal)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalOrd() {
    let total = this.getTotalOrd();
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvOrd() {
    let total = this.getTotalOrd();
    return (
      parseFloat(this.getTotalOrd()) - parseFloat(this.getSubTotalOrd())
    ).toFixed(2);
  }
  seleccionartipopago() {
    console.log(this.tipopago);
    switch (this.tipopago.toString()) {
      case "0":
        console.log(this.tipopago);
        this.swpago = true;
        this.pago = parseFloat("0.00").toFixed(2);
        break;
      case "1":
        console.log(this.tipopago);
        this.swpago = true;
        this.pago = parseFloat("0.00").toFixed(2);
        break;
      case "2":
        console.log(this.tipopago);
        this.swpago = false;
        this.pago = parseFloat("0.00").toFixed(2);
        break;
      case "3":
        console.log(this.tipopago);
        this.swpago = true;
        this.pago =this.getTotalCost();
          // this.pedido.id == 0 ? this.getTotalCost() : this.getTotalCostAtend();
        break;
    }
  }
  async listar_proveedor() {
    this.spinner.show();
    console.log("USUARIO " + JSON.stringify(this.constants.usuario_tda));
    let request = {
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_proveedor_vigente");
    await axios
      .post(this.constants.apiUrl + "lista_proveedor_vigente", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        this.proveedor_lst = new Array<Organizacion>();
        let organizacion_auxlst = response.data.data;
        if (response.data.estado) {
          if (organizacion_auxlst.length > 0) {
            this.proveedor_lst.push(
              new Organizacion(
                0,
                "Seleccione...",
                "",
                "",
                "",
                "",
                "",
                0,
                "",
                0,
                "",
                "",
                "",
                false,
                false,
                false,
                false,
                "",
                "",
                false,
                null,
                0,
                null,
                0
              )
            );
            organizacion_auxlst.forEach((item) => {
              this.proveedor_lst.push(
                new Organizacion(
                  item.id,
                  item.empresa,
                  item.tipodocumento,
                  item.documento,
                  item.representante,
                  item.email,
                  item.telefono,
                  item.negocio,
                  item.otrosector,
                  item.sector,
                  item.pais,
                  item.ciudad,
                  item.codigopostal == null || item.codigopostal == "null"
                    ? "00000"
                    : item.codigopostal,
                  true,
                  false,
                  false,
                  item.vigencia,
                  item.mensaje,
                  "",
                  null,
                  null,
                  null,
                  null,
                  null
                )
              );
            });
          }
        } else {
          swal("Datos de organización", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  seleccionar_proveedor($id: number) {
    if (this._cantprod > 0) {
      if (this.proveddor_reg.org_id !== this._cantprod) {
        swal(
          "Registro de Pedidos",
          "No puede seleccionar otro proveedor ya que tiene productos en la lista.",
          "info"
        ).then(() => {
          this.proveddor_reg.org_id = this._cantprod;
        });
      } else {
        this.constants.listado_productos($id);
      }
    } else {
      this.constants.listado_productos($id);
    }
  }

  formatter = (producto: any) => producto.prod_descripcion;

  searchprod = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === ""
          ? []
          : this.constants.productostda_lst
              .filter((producto) =>
                new RegExp(term, "mi").test(producto.prod_descripcion)
              )
              .slice(0, 10)
      )
    );

  nuevo() {
    this.pedido = new Pedido(
      0,
      0,
      null,
      0,
      0,
      "",
      0,
      false,
      0,
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      "",
      "REGISTRANDO NUEVO PEDIDO",
      0.0, 0
    );

    this._show = true;
    this._disabled = false;
    this._showedit = false;
    this._showeditdet = false;
    this._showdetalle = true;
    this._showdlista = true;
    this._verpedido = false;
    this._cantprod = 0;
    this.prodpedido_lst = new Array<DetallePedido>();
    this.fechaventa = this.calendar.getToday();

    this.tipopago = 3;
    this.seleccionartipopago();
  }

  editar() {
    this._showedit = true;
    this._showdlista = false;
    this._showdetalle = true;
  }

  eliminar(estado: boolean) {}

  listar() {
    this._showdlista = true;
    this._showdetalle = false;
    this._show = false;
    this._verpedido = false;
    this.listarordeningreso();
  }

  async registrar($state: boolean) {
    //true:ingreso sin pedido - false: //desde pedido
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );

    if (this._showeditdet) {
      swal(
        "Registro de ingreso",
        "EL detalle del pedido está edición, acepte los cambios.",
        "info"
      );
      return false;
    }
    if (this.tipopago == 0) {
      swal("Registro de ingreso", "Debe seleccionar tipo de pago.", "info");
      return false;
    }
    if (this.tipopago == 2 && this.pago <= 0.0) {
      swal("Registro de ingreso", "Debe ingresar el pago a realizar.", "info");
      return false;
    }
    let detpedido: Array<DetallePedido>;
    detpedido = new Array<DetallePedido>();

    console.log(this.prodpedido_lst);
    this.prodpedido_lst.forEach((item) => {
      if (item.preciovender == 0) {
        swal(
          "Ingreso de productos",
          "Ingrese precio sugerido que venderá del producto " + item.producto,
          "info"
        );
        return false;
      }
      if (item.preciovender < item.precioUnit) {
        swal(
          "Ingreso de productos",
          "Precio sugerido de " +
            item.producto +
            " no puede ser menor que precio Unitario",
          "info"
        );
        return false;
      }
      detpedido.push(
        new DetallePedido(
          item.id,
          this.pedido.id,
          item.idProducto,
          item.cantidad,
          item.cantidadAtendida,
          item.cantidadRecibida,
          item.cantidadRecibida,
          item.cantidad - item.cantidadAtendida,
          item.precioUnit,
          item.precioTotal,
          item.vigencia,
          item.producto,
          item.precioAtendido,
          0,
          0, "",
          item.unidades
        )
      );
    });

    let detalleorden: Array<DetalleOrden>;
    detalleorden = new Array<DetalleOrden>();
    this.prodpedido_lst.filter(item => item.cantidadRecibida > 0).forEach((item) => {
      detalleorden.push(
        new DetalleOrden(
          0,
          0,
          item.idProducto,
          $state ? 0 : this.pedido.idProveedor,
          1,
          $state
            ? item.cantidad : item.cantidadRecibida * item.unidades,
          0,//item.precioUnit,
          $state
            ? item.precioUnit
            : (
              parseFloat(item.precioAtendido) /
              (parseFloat(item.unidades) * parseFloat(item.cantidadRecibida))
              ).toFixed(2),
              $state
              ? item.precioTotal : item.precioAtendido,
          item.preciovender,
          item.vigencia,
          item.producto,
          $state
            ? item.cantidad : item.cantidadRecibida * item.unidades ,
          0,
          item.precioTotal
        )
      );
    });
    console.log(parseFloat(this.getTotalCost()));
    console.log(parseFloat(this.getTotalCostAtend()));
    let data = {
      id: 0,
      idProveedor: $state ? 0 : this.pedido.idProveedor,
      idCliente: 0,
      idPedido: $state ? 0 : this.pedido.id,
      fecha:
        this.fechaventa["year"] +
        "-" +
        this.fechaventa["month"] +
        "-" +
        this.fechaventa["day"],
      idVenta: 0,
      estado: this.pedido.estado == 4 ? 6 : 7,
      idTienda: tienda_aux.tda_id,
      tipoPago: this.tipopago,
      montopago: this.pago,
      tipoorden: 1, // 1: Ingreso compra, 2: Venta, 3: Devolución de cliente, 4: Devolución a proveedor
      total: $state ? this.getTotalCost() : this.getTotalCostAtend(),
      subTotal: $state ? this.getSubTotalCost() : this.getSubTotalCostAtend(),
      igv: $state ? this.getIgvCost() : this.getIgvCostAtend(),
      detallepedido: this.prodpedido_lst,
      detalleorden: detalleorden,
    };
    console.log("ORDEN DE INGRESO : " + JSON.stringify(data));
    this.spinner.show();
    await axios
      .post(this.constants.apiUrl + "registro_orden", data, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        if (response.data.estado) {
          swal("Registro Ingreso", response.data.mensaje, "success").then(
            () => {
              this.ExportToPDFOrden(response.data.codigo).then(()=>{
                this.listar()
              })
              if (this.pedido.id > 0) {
                this.verlista();
              } else {
                this.nuevo();
              }
            }
          );
        } else {
          swal("Registro Ingreso", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        // swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });

    // this.nuevo();
  }

  cancelar(estado: boolean) {
    // true: registro / false:listado
    if (estado) {
      this._verpedido = true;
      this.verlista();
    } else {
      this.nuevo();
    }
  }

  verlista() {
    this._show = false;
    this._showdetalle = false;
    this.listarpedidos();
  }

  agregar() {
    let _ban = true;
    let prod_cantidad = this.cantidadprod;
    this.prodpedido_lst.forEach((item) => {
      if (item.idProducto == this.modelproducto.prod_id) {
        _ban = false;
      }
    });
    if (_ban) {
      if (prod_cantidad <= 0) {
        swal(
          "Registro de pedidos",
          "Ingrese cantidad para " + this.modelproducto.prod_descripcion,
          "info"
        );
        return false;
      } else if (this.precioventa <= 0) {
        swal(
          "Registro de pedidos",
          "Ingrese precio sugeridad que venderá el producto por unidad",
          "info"
        );
        return false;
      } else {
        this.modelproducto.prod_preciototal = (
          prod_cantidad * this.modelproducto.prod_precio
        ).toFixed(2);

        this.prodpedido_lst.push(
          new DetallePedido(
            0,
            0,
            this.modelproducto.prod_id,
            parseFloat(prod_cantidad).toFixed(2),
            parseFloat(prod_cantidad).toFixed(2),
            parseFloat(prod_cantidad).toFixed(2),
            parseFloat(prod_cantidad).toFixed(2),
            0,
            parseFloat(this.modelproducto.prod_precio).toFixed(2),
            parseFloat(
              (prod_cantidad * this.modelproducto.prod_precio).toString()
            ).toFixed(2),
            true,
            this.modelproducto.prod_descripcion,
            0,
            parseFloat(this.precioventa.toString()).toFixed(2),
            0, "",
            this.orden_reg.idProveedor == 0
              ? 1
              : parseFloat(this.modelproducto.prod_cantidad).toFixed(2)
          )
        );
        this.seleccionartipopago();
        // this.dataSource = this.prodpedido_lst;
      }
      if (this.prodpedido_lst.length > 0) {
        this._showedit = true;
      }
      this.modelproducto = null;
      this.cantidadprod = 0;
      this.precioventa = 0;
    } else {
      swal(
        "Registro de pedidos",
        "Producto " +
          this.modelproducto.prod_descripcion +
          " ya ha sido agregado.",
        "info"
      );
      this.modelproducto = null;
      this.cantidadprod = 0;
      this.precioventa = 0;
    }
    if (this.prodpedido_lst.length == 1) {
      this._cantprod = this.proveddor_reg.org_id;
    }
  }

  agregar_predido() {
    this._show = false;
    this._showdlista = false;
    this._showedit = true;
    this._verpedido = true;
    this.listarpedidos();
  }

  getTotalCost() {
    return this.prodpedido_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioTotal) == "number"
            ? sum + parseFloat(value.precioTotal)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalCost() {
    let total = this.getTotalCost();
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvCost() {
    let total = this.getTotalCost();
    return (
      parseFloat(this.getTotalCost()) - parseFloat(this.getSubTotalCost())
    ).toFixed(2);
  }

  getTotalCostAtend() {
    return this.prodpedido_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioTotal) == "number"
            ? sum + parseFloat(value.precioAtendido)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalCostAtend() {
    let total = this.getTotalCostAtend();
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvCostAtend() {
    let total = this.getTotalCostAtend();
    return (
      parseFloat(this.getTotalCostAtend()) -
      parseFloat(this.getSubTotalCostAtend())
    ).toFixed(2);
  }
  async listarpedidos() {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idtienda: tiendaaux.tda_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_pedidos_atendidos");
    this.lstpedido = new Array<Pedido>();
    await axios
      .post(this.constants.apiUrl + "lista_pedidos_atendidos", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let pedido_lst_aux = response.data.data;
        if (response.data.estado) {
          if (response.data.data != null || response.data.data != "null") {
            for (let element in pedido_lst_aux) {
              let pedido: Pedido = new Pedido(
                pedido_lst_aux[element].id,
                pedido_lst_aux[element].idProveedor,
                pedido_lst_aux[element].fechaPedido,
                pedido_lst_aux[element].idEmpresa,
                pedido_lst_aux[element].idTienda,
                pedido_lst_aux[element].direccion,
                pedido_lst_aux[element].estado,
                pedido_lst_aux[element].beneficio,
                parseFloat(pedido_lst_aux[element].total).toFixed(2),
                parseFloat(pedido_lst_aux[element].igv).toFixed(2),
                parseFloat(pedido_lst_aux[element].subtotal).toFixed(2),
                pedido_lst_aux[element].vigencia == 1 ? true : false,
                pedido_lst_aux[element].idUsuRegistro,
                pedido_lst_aux[element].fecharegistro,
                pedido_lst_aux[element].idProvActualiza,
                pedido_lst_aux[element].fechaactualiza,
                pedido_lst_aux[element].empresa,
                this.constants.estado_pedido[
                  parseInt(pedido_lst_aux[element].estado)
                ],
                parseFloat(pedido_lst_aux[element].precioAtendido).toFixed(2), 0
              );
              this.lstpedido.push(pedido);
            }
          }
        }
        // else {
        //   swal("Ingreso de Productos ", response.data.mensaje, "info");
        // }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async ingresoproducto($pedido: Pedido) {
    this._verpedido = false;
    this.pedido = $pedido;
    this.listardetalle($pedido.id);
    this.tipopago = 1
    this.seleccionartipopago()
  }

  async listardetalle($idpedido: number) {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idpedido: $idpedido,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_detallepedido");
    await axios
      .post(this.constants.apiUrl + "lista_detallepedido", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let detalle_lst_aux = response.data.data;
        this.prodpedido_lst = new Array<DetallePedido>();
        if (response.data.estado) {
          this.prodpedido_lst = detalle_lst_aux;
          this.prodpedido_lst.forEach((item) => {
            item.cantidad = parseFloat(item.cantidad).toFixed(2);
            item.cantidadAtendida = parseFloat(item.cantidadAtendida).toFixed(
              2
            );
            item.cantidadFaltante = parseFloat(item.cantidadFaltante).toFixed(
              2
            );
            item.cantidadRecibida = parseFloat(item.cantidadRecibida).toFixed(
              2
            );
            item.cantidadRecibidaAct = parseFloat(
              item.cantidadRecibidaAct
            ).toFixed(2);
            item.precioTotal = parseFloat(item.precioTotal).toFixed(2);
            item.precioUnit = parseFloat(item.precioUnit).toFixed(2);
            item.preciovender = parseFloat(item.preciovender).toFixed(2);
            item.precioAtendido = parseFloat(item.precioAtendido).toFixed(2);
          });
        } else {
          swal("Detalle de pedido ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal(
          "Ha ocurrido un error al listar detalle de pedido!",
          error.message,
          "error"
        );
        console.log("tipo error " + error);
      });
  }

  edit_detail($state: boolean) {
    if (!$state) {
      this.prodpedido_lst.forEach((item) => {
        item.precioTotal = (item.cantidadRecibida * item.precioUnit).toFixed(2);
      });
      this.seleccionartipopago();
    }
    this._showeditdet = $state;
  }

  edit_detail2($detped: DetallePedido) {
    console.log($detped);
    if (parseFloat($detped.cantidadRecibida) < parseFloat($detped.cantidadRecibidaAct)) {
      swal(
        "Ingreso de productos",
        "Cantidad recibida no puede ser menor que la cantidad atendida.",
        "info"
      );
      $detped.cantidadRecibida = $detped.cantidadRecibidaAct;
      return false;
    }
    if (parseFloat($detped.cantidadRecibida) > parseFloat($detped.cantidadRecibidaAct)) {
      swal(
        "Ingreso de productos",
        "Cantidad recibida no puede ser mayor que la cantidad atendida.",
        "info"
      );
      $detped.cantidadRecibida = $detped.cantidadRecibidaAct;
      return false;
    }

    this.prodpedido_lst.forEach((item) => {
      item.precioAtendido = (item.cantidadRecibida * item.precioUnit).toFixed(
        2
      );
    });
    this.seleccionartipopago();
  }

  async listarordeningreso() {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idtienda: tiendaaux.tda_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_ordeningreso");
    this.lstpedido = new Array<Pedido>();
    await axios
      .post(this.constants.apiUrl + "lista_ordeningreso", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let pedido_lst_aux = response.data.data;
        if (response.data.estado) {
          if (response.data.data != null || response.data.data != "null") {
            this.orden_lst = response.data.data;
          }
        } else {
          swal("Ingreso de Productos ", response.data.mensaje, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async pagar() {
    if (parseFloat(this.montocobro) > parseFloat(this.deuda)) {
      swal("Pagos", "El monto a pagar es mayor a la deuda", "info");
    } else {
      let tienda_aux: Tienda = JSON.parse(
        localStorage.getItem("datostienda_tda")
      );
      let data = {
        idOrden: this.orden_reg.id,
        fecha:
          this.fechaventa["year"] +
          "-" +
          this.fechaventa["month"] +
          "-" +
          this.fechaventa["day"],
        tipo: false,
        idTienda: tienda_aux.tda_id,
        montopago: this.montocobro,
      };
      // this.spinner.show();
      console.log("COBRO: " + JSON.stringify(data));

      await axios
        .post(this.constants.apiUrl + "registro_cobropago", data, {
          headers: this.constants.headers_aut,
        })
        .then((response) => {
          console.log(response.data);
          this.spinner.hide();
          if (response.data.estado) {
            swal("Pagos", response.data.mensaje, "success").then(() => {
              this.listar();
              this.modalService.dismissAll();
            });
          } else {
            swal("Pagos", response.data.mensaje, "info");
          }
        })
        .catch(function (error) {
          this.spinner.hide();
          // swal("Ha ocurrido un error al registrar!", error.message, "error");
          console.log("tipo error " + error);
        });
    }
  }

  verdetalle(content, $venta: Orden) {
    this.detorden_lst = new Array<DetalleOrden>();
    this.orden_reg = $venta;
    this.orden_reg.montopago = parseFloat($venta.montopago).toFixed(2);
    this.orden_reg.total = parseFloat($venta.total).toFixed(2);
    this.orden_reg.montodevuelto = parseFloat($venta.montodevuelto).toFixed(2);
    let totalamort: number =
      parseFloat($venta.montopago) + parseFloat($venta.montodevuelto);
    this.deuda = ($venta.total - totalamort).toFixed(2);
    this.listardetalleorden($venta.id);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  verpagar(content, $venta: Orden) {
    this.detorden_lst = new Array<DetalleOrden>();
    this.orden_reg = $venta;
    this.orden_reg.montopago = parseFloat($venta.montopago).toFixed(2);
    this.orden_reg.total = parseFloat($venta.total).toFixed(2);
    this.orden_reg.montodevuelto = parseFloat($venta.montodevuelto).toFixed(2);
    let totalamort: number =
      parseFloat($venta.montopago) + parseFloat($venta.montodevuelto);
    this.deuda = ($venta.total - totalamort).toFixed(2);
    this.montocobro = this.deuda;
    this.listardetalleorden($venta.id);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  dataForExcel = [];
   empPerformance = []

  ExportToExcel()
  {
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table_exp.nativeElement);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // /* save to file */
    // XLSX.writeFile(wb, 'cierrecaja.xlsx');
    this.empPerformance = [];
    this.orden_lst.forEach((item) => {
      this.empPerformance.push({ "FECHA":item.fecha.toString().substr(0,10), "ESTADO":this.constants.tipopago[item.tipoPago],"PROVEEDOR":item.proveedor, "MONTO": parseFloat(item.total).toFixed(2)})
    });
    this.empPerformance.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })
   let reportData = {
      title: 'Ordenes de Ingreso',
      data: this.dataForExcel,
      headers: Object.keys(this.empPerformance[0]),
      description: "Ordenes de ingreso  descargado el día "
    }

    this.ete.exportExcel(reportData);


  }
  async ExportExcel(){
    let data: data4col[] = []
    data.push({data1:'FECHA',data2:'ESTADO', data3:'PROVEEDOR', data4:'MONTO'})
    this.orden_lst.forEach((item) => {
      data.push({data1:item.fecha.toString().substr(0,10), data2:this.constants.tipopago[item.tipoPago], data3: item.proveedor, data4: parseFloat(item.total).toFixed(2)})
    });
    let title= 'Ordenes de Ingreso';
    window.open(
      this.constants.apiUrl +
        "excel-reporte?data=" +
        JSON.stringify(data) + "&nombre="+title+"&col=4",
      "_blank"
    );
  }

  async ExportToPDF($id){
    window.open(
      this.constants.apiUrl +
        "descargar-pedido?idPedido=" +
        $id,
      "_self"
    );

  }

  async ExportToPDFOrden($id){
    window.open(
      this.constants.apiUrl +
        "descargar-ordeningreso?idOrden=" +
        $id,
      "_self"
    );

  }
}
