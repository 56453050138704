import { Component, OnInit, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Producto, IProducto } from "src/app/models/producto";
import * as XLSX from "xlsx";
const swal: SweetAlert = _swal as any;

import {
  NgbDateStruct,
  NgbTypeaheadConfig,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { Observable, empty } from "rxjs";
import { isNumber, isDate } from "util";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  filter,
  isEmpty,
} from "rxjs/operators";

import { element, $ } from "protractor";
import { HorarioEntrega, Organizacion, proveedorcbo } from "src/app/models/organizacion";
import { Tienda } from "src/app/models/tienda";
import { DetallePedido, Pedido } from "src/app/models/pedido";
import { Categoria } from "src/app/models/categoria";
import { ExportExcelService } from "src/app/services/export-excel.service";
import { data4col } from "src/app/models/exportexcel";

@Component({
  selector: "regpedidos",
  templateUrl: "./regpedidos.component.html",
  // providers: [NgbTypeaheadConfig],
})
export class RegPedidoComponent implements OnInit {
  public title: string;
  public rute: string;
  public _cantprod: number = 0;
  public _show: boolean = true;
  public _disabled: boolean = false;
  public _showedit: boolean = false;
  public _showeditdet: boolean = false;
  public _showdetalle: boolean = true;
  public _showdlista: boolean = true;
  // public _numlst_lg: string = "12";
  // public _numlst_md: string = "12";
  public closeResult = "";
  public _model: any;
  public _modelsubtotal: any;
  public _modeligv: any;
  public _modeltotal: any;
  public _comstants_producto: any;
  public modelproducto: any;
  public cantidadprod: any = 0;
  public prodpedido_lst: Array<DetallePedido>;
  public detpedido_lst: Array<DetallePedido>;
  public searchinput: string;
  searchinputprov: string;
  displayedColumns = [
    "prod_descripcion",
    "prod_cantidad",
    "prod_precio",
    "prod_preciototal",
  ];
  dataSource: Array<DetallePedido> ;
  model: NgbDateStruct;
  placement = "left";
  public fechaventa: NgbDate;
  public minFecha: NgbDate;
  public maxFecha: NgbDate;
  public proveedor_lst: Array<Organizacion>;
  public proveddor_reg: Organizacion;
  public pedido: Pedido;
  public lstpedido: Array<Pedido>;
  public _cantped = false;
  private _success = new Subject<string>();
  public categoria_lst: Array<Categoria>;
  public subcategoria_lst: Array<Categoria>;
  public categoriaproducto_lst: Array<Categoria>;
  public tipo: number = 0;
  public prod_idProveedor:number = 0
  public prod_turno:string = "TODOS"
  public prod_idCategoria:number = 0
  public prod_idSubCategoria:number = 0
  public dis: boolean = false
  staticAlertClosed = false;
  successMessage = "";
  public prov_ped: number[];
  public _cart: boolean = false;
  public typealert: string = "";
  public horariolst: Array<HorarioEntrega>
  public horariofil: Array<HorarioEntrega>
  public cboproveedor: Array<proveedorcbo>
  provbus: string = 'TODOS'
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    config: NgbTypeaheadConfig,
    private el: ElementRef,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.title = "Pedidos a proveedores";
    this.rute = "tda/pedidos";
    this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
    this.dataSource = this.prodpedido_lst;
    this.proveedor_lst = new Array<Organizacion>();
    this.prodpedido_lst = new Array<DetallePedido>();
    this.detpedido_lst = new Array<DetallePedido>();
    this.categoria_lst = new Array<Categoria>();
    this.subcategoria_lst = new Array<Categoria>();
    this.categoriaproducto_lst = new Array<Categoria>();
    this.horariolst = new Array<HorarioEntrega>()
    this.horariofil = new Array<HorarioEntrega>()
    this.dataSource = this.prodpedido_lst;
    this.cboproveedor= new Array<proveedorcbo>()
    this.proveddor_reg = new Organizacion(
      0,
      "Seleccione...",
      "",
      "",
      "",
      "",
      "",
      0,
      "",
      0,
      "",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      false,
      null,
      0,
      null,
      0
    );
    this.pedido = new Pedido(
      0,
      0,
      null,
      0,
      0,
      "",
      0,
      false,
      0,
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      "",
      "REGISTRANDO NUEVO PEDIDO",
      0, 0
    );
    this.nuevo();
    this.lstpedido = new Array<Pedido>();
  }

  ngOnInit() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.fechaventa = this.calendar.getToday();
    this.minFecha = this.calendar.getToday();
    this.maxFecha = this.calendar.getToday();
    this.listar_proveedor();
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    if(tienda_aux.tda_direccion == null){
      swal(
        "Registro de pedidos",
        "Vaya a Registro de Tiendas e ingrese dirección para proceder con el pedido",
        "info"
      );
    }else if (tienda_aux.tda_direccion.trim() == "") {
      swal(
        "Registro de pedidos",
        "Vaya a Registro de Tiendas e ingrese dirección para proceder con el pedido",
        "info"
      );
    }
    this.spinner.show();
    setTimeout(() => {
      this.constants.listado_productos_filtro(0, 0, "TODOS");
  });
  this.listado_categoria_productos();

    setTimeout(() => (this.staticAlertClosed = true), 20000);

    this._success.subscribe((message) => (this.successMessage = message));
    this._success
      .pipe(debounceTime(5000))
      .subscribe(() => (this.successMessage = ""));

    if (this.constants.pedido_cart.length > 0) {
      this.typealert = "info";
      this.prodpedido_lst = this.constants.pedido_cart;
      this.prov_ped = this.constants.prov_cart;
      this._success.next(
        "Tiene " +
          this.prodpedido_lst.length +
          " productos agregados al carrito de pedidos."
      );
    }
  }

  emptyval($val: Producto){
    console.log($val.prod_cantpedido)
    if($val.prod_cantpedido == null || $val.prod_cantpedido <= 0){
      $val.prod_cantpedido = 1;
    }
  }

  filtrar(content, $ban: boolean = true) {
    if(!$ban){this.listar_horarioprov()}
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass : "modal-dialog-cb" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  async listado_categoria_productos() {
    this.spinner.show();
    let request = {};
    request = {
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista__categoria_productos", request, {
        headers: this.constants.headers,
      })
      .then((response) => {
        console.log(response.data);
        let categoria_lst_aux = response.data.data;
        let categoria_aux: Categoria;
        if (response.data.estado) {
          if (categoria_lst_aux != null) {
            this.categoria_lst.push(
              new Categoria(
                0,
                "",
                "TODOS",
                false,
                0,
                0,
                null,
                0,
                null,
                0,
                "",
                ""
              )
            );
            this.subcategoria_lst.push(
              new Categoria(
                0,
                "",
                "TODOS",
                false,
                0,
                0,
                null,
                0,
                null,
                0,
                "",
                ""
              )
            );
            for (let element in categoria_lst_aux) {
              //console.log("num " + element);
              categoria_aux = new Categoria(
                categoria_lst_aux[element].id,
                categoria_lst_aux[element].codigo,
                categoria_lst_aux[element].descripcion,
                categoria_lst_aux[element].vigencia == 1 ? true : false,
                categoria_lst_aux[element].superior,
                categoria_lst_aux[element].idCategoria,
                null,
                0,
                null,
                0,
                "",
                ""
              );
              if (categoria_lst_aux[element].tipo == "CATEGORIA") {
                this.categoria_lst.push(categoria_aux);
              }
              this.categoriaproducto_lst.push(categoria_aux);
            }
          }
          this.spinner.hide();
        } else {
          this.spinner.hide();
          swal("Registro de productos", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log(error);
      });
  }

  seleccionar_superior($id: number, $ban: boolean) {
    let categoria_aux: Categoria;
    this.subcategoria_lst = new Array<Categoria>();
    this.subcategoria_lst.push(
      new Categoria(
        0,
        "",
        "TODOS",
        false,
        0,
        0,
        null,
        0,
        null,
        0,
        "",
        ""
      )
    );

    if ($id > 0) {
      console.log("SUPERIOR " + $id);
      this.categoriaproducto_lst.forEach((item, index) => {
        if (item.cat_superior == $id) {
          categoria_aux = new Categoria(
            item.cat_id,
            item.cat_codigo,
            item.cat_descripcion,
            item.cat_vigencia,
            item.cat_superior,
            item.cat_idCategoria,
            null,
            0,
            null,
            0,
            "",
            ""
          );
          this.subcategoria_lst.push(categoria_aux);
        }
      });
    }

  }

  listar_horarios(){
    this.horariofil = this.horariolst
    this.horariolst = this.horariofil.filter(item => item.empresa == this.provbus)
  }
  search_horario($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.horariolst = new Array<HorarioEntrega>();
    this.horariolst = this.horariofil.filter(
      (item: HorarioEntrega) =>
      item.departamento.toLocaleLowerCase().indexOf($txt) !== -1 ||
      item.provincia.toLocaleLowerCase().indexOf($txt) !== -1 ||
      item.distrito.toLocaleLowerCase().indexOf($txt) !== -1
    );
  }


  search_producto($txt: string) {
    $txt = $txt.toLocaleLowerCase();
    this.constants.productos_lst = new Array<Producto>();
  if (this.tipo == 0) {
    this.constants.productos_lst = this.constants.productos_fil.filter(
      (producto: Producto) =>
        producto.prod_descripcion.toLocaleLowerCase().indexOf($txt) !== -1
    );
  } else if (this.tipo == 1) {
    this.constants.productos_lst  = this.constants.productos_fil.filter(
      (producto: Producto) =>
        producto.prod_codigo.toLocaleLowerCase().indexOf($txt) !== -1
    );
  }else if (this.tipo == 2) {
    this.constants.productos_lst = this.constants.productos_fil.filter(
      (producto: Producto) =>
        producto.prod_categoria.toLocaleLowerCase().indexOf($txt) !== -1
    );
  }
  }

  formatter = (producto: any) => producto.prod_descripcion;

  searchprod = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === "" || this.proveddor_reg.org_id == 0
          ? []
          : this.constants.productos_lst //this.constants.productos
              .filter((producto) =>
                new RegExp(term, "mi").test(producto.prod_descripcion)
              )
              .slice(0, 10)
      )
    );

  nuevo() {
    if (this.pedido.id > 0 && this.pedido.estado == 1) {
      this.cambiarestado(this.pedido, 0).then(() => {
        this.pedido = new Pedido(
          0,
          0,
          null,
          0,
          0,
          "",
          0,
          false,
          0,
          0,
          0,
          false,
          0,
          null,
          0,
          null,
          "",
          "REGISTRANDO NUEVO PEDIDO",
          0, 0
        );
      });
    }
    this.pedido = new Pedido(
      0,
      0,
      null,
      0,
      0,
      "",
      0,
      false,
      0,
      0,
      0,
      false,
      0,
      null,
      0,
      null,
      "",
      "REGISTRANDO NUEVO PEDIDO",
      0, 0
    );

    this._show = true;
    this._disabled = false;
    this._showedit = false;
    this._showeditdet = false;
    this._showdetalle = true;
    this._showdlista = true;
    this._cantprod = 0;
    this.prodpedido_lst = new Array<DetallePedido>();
    this.fechaventa = this.calendar.getToday();
    this.prov_ped = [];
  }



  async eliminar($pedido: Pedido, $estado: boolean) {
    if ($pedido.estado == 0 || $pedido.estado == 8) {
      let data = {
        idPedido: $pedido.id,
        vigencia: $estado,
        estado: $estado ? 0 : 8,
      };
      console.log(JSON.stringify(data));
      await axios
        .post(this.constants.apiUrl + "vigencia_pedido", data, {
          headers: this.constants.headers_aut,
        })
        .then((response) => {
          swal("Registro de Pedido ", response.data.mensaje, "success").then(
            () => {
              this.verlista();
            }
          );
        })
        .catch(function (error) {
          swal("Ha ocurrido un error al registrar!", error.message, "error");
          console.log("tipo error " + error);
        });
    } else {
      swal("Registro de Pedido", "No se puede cancelar este pedido.", "info");
    }
  }

  async listar_horarioprov() {
    this.horariofil = new Array<HorarioEntrega>()
    this.horariolst = new Array<HorarioEntrega>()
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idtienda: tiendaaux.tda_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "list_horarioproveedor");
    this.lstpedido = new Array<Pedido>();
    await axios
      .post(this.constants.apiUrl + "list_horarioproveedor", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        let pedido_lst_aux = response.data.data;
        if (response.data.estado) {
          this.horariofil = response.data.data
          this.horariolst = response.data.data
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async listar() {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idtienda: tiendaaux.tda_id,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_pedido");
    this.lstpedido = new Array<Pedido>();
    await axios
      .post(this.constants.apiUrl + "lista_pedido", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let pedido_lst_aux = response.data.data;
        if (response.data.estado) {
          for (let element in pedido_lst_aux) {
            let pedido: Pedido = new Pedido(
              pedido_lst_aux[element].id,
              pedido_lst_aux[element].idProveedor,
              pedido_lst_aux[element].fechaPedido,
              pedido_lst_aux[element].idEmpresa,
              pedido_lst_aux[element].idTienda,
              pedido_lst_aux[element].direccion,
              pedido_lst_aux[element].estado,
              pedido_lst_aux[element].beneficio,
              parseFloat(pedido_lst_aux[element].total).toFixed(2),
              parseFloat(pedido_lst_aux[element].igv).toFixed(2),
              parseFloat(pedido_lst_aux[element].subtotal).toFixed(2),
              pedido_lst_aux[element].vigencia == 1 ? true : false,
              pedido_lst_aux[element].idUsuRegistro,
              pedido_lst_aux[element].fecharegistro,
              pedido_lst_aux[element].idProvActualiza,
              pedido_lst_aux[element].fechaactualiza,
              pedido_lst_aux[element].documento  +pedido_lst_aux[element].empresa,
              this.constants.estado_pedido[
                parseInt(pedido_lst_aux[element].estado)
              ],
              parseFloat(pedido_lst_aux[element].precioatentida).toFixed(2),
                parseFloat(pedido_lst_aux[element].pago).toFixed(2)
            );
            this.lstpedido.push(pedido);
          }
        } else {
          swal("Datos de tiendas ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  async listardetalle($idpedido: number) {
    // this._showdlista = false;
    // this._showdetalle = true;
    // this._showedit = true;
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idpedido: $idpedido,
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_detallepedido");
    this.lstpedido = new Array<Pedido>();
    await axios
      .post(this.constants.apiUrl + "lista_detallepedido", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let detalle_lst_aux = response.data.data;
        this.prodpedido_lst = new Array<DetallePedido>();
        if (response.data.estado) {
          for (let element in detalle_lst_aux) {
            this.prodpedido_lst.push(detalle_lst_aux[element]);
          }
        } else {
          swal("Detalle de pedido ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal(
          "Ha ocurrido un error al listar detalle de pedido!",
          error.message,
          "error"
        );
        console.log("tipo error " + error);
      });
  }

  async registrar() {
    let tienda_aux: Tienda = JSON.parse(
      localStorage.getItem("datostienda_tda")
    );
    let _ban: boolean = true;
    let _cant_reg: number = 0;
    let _mensaje: string = "";
    if (this._showeditdet) {
      swal(
        "Registro de pedidos",
        "EL detalle del pedido está edición, acepte los cambios.",
        "info"
      );
    } else {
      if(tienda_aux.tda_direccion == null){
        swal(
          "Registro de pedidos",
          "Vaya a Registro de Tiendas e ingrese dirección para proceder con el pedido",
          "info"
        );
      }else if (tienda_aux.tda_direccion.trim() == "") {
        swal(
          "Registro de pedidos",
          "Vaya a Registro de Tiendas e ingrese dirección para proceder con el pedido",
          "info"
        );
      } else {
        let detpedido: Array<DetallePedido>;

        console.log(this.prodpedido_lst);
        this.prov_ped.forEach(async (item_pv) => {
          detpedido = new Array<DetallePedido>();
          this.prodpedido_lst
            .filter((detped) => detped.idProveedor === item_pv)
            .forEach((item) => {
              if (item.id === 0) {
                detpedido.push(
                  new DetallePedido(
                    item.id,
                    this.pedido.id,
                    item.idProducto,
                    item.cantidad,
                    item.cantidadAtendida,
                    item.cantidadRecibida,
                    item.cantidadRecibida,
                    item.cantidad,
                    item.precioUnit,
                    item.precioTotal,
                    item.vigencia,
                    item.producto,
                    item.precioAtendido,
                    0,
                    0, "",
                    item.unidades
                  )
                );
              }
            });
          let data = {
            id: 0,
            idTienda: tienda_aux.tda_id,
            idEmpresa: tienda_aux.tda_idNegocio,
            direccion:
              tienda_aux.tda_direccion +
              " - " +
              tienda_aux.tda_ciudad +
              " \nReferencia: " +
              tienda_aux.tda_referencia,
            idProveedor: item_pv,
            fechaPedido:
              this.fechaventa["year"] +
              "-" +
              this.fechaventa["month"] +
              "-" +
              this.fechaventa["day"],
            estado: 0,
            beneficio: false,
            total: this.getTotalCostProv(item_pv),
            subTotal: this.getSubTotalCostProv(item_pv),
            igv: this.getIgvCostProv(item_pv),
            detallepedido: detpedido,
          };
          console.log(JSON.stringify(data));
          await axios
            .post(this.constants.apiUrl + "registro_pedido", data, {
              headers: this.constants.headers_aut,
            })
            .then((response) => {
              console.log(response.data);
              this.spinner.hide();
              if (response.data.estado) {
                _cant_reg = _cant_reg + 1;
              } else {
                _ban = false;
                _mensaje = response.data.mensaje;
              }
            })
            .catch(function (error) {
              this.spinner.hide();
              swal(
                "Ha ocurrido un error al registrar!",
                error.message,
                "error"
              );
              console.log("tipo error " + error);
            });

          if (_ban) {
            swal(
              "Registro pedido",
              "Se realizó el registro de " + _cant_reg + " pedidos.",
              "success"
            ).then(() => {
              if (this.pedido.id > 0) {
                this.verlista();
              } else {
                this.nuevo();
                if (this.prodpedido_lst.length == 0) {
                  setTimeout(() => {
                    this.typealert = "danger";
                    this._success.next("Agregar productos al carrito");
                    this._cart = false;
                  }, 2000);
                }
              }
            });
          } else {
            swal("Registro pedido", _mensaje, "info");
          }
        });
      }
    }
  }

  async cambiarestado($pedido: Pedido, $estado: number) {
    let data = {
      idpedido: $pedido.id,
      estado: $estado,
    };
    console.log(JSON.stringify(data));
    await axios
      .post(this.constants.apiUrl + "estado_pedido", data, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        this.pedido.estado = $estado;
        this.pedido.descestado = this.constants.estado_pedido[$estado];
        console.log(response.data.mensaje);
      })
      .catch(function (error) {
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  // async eliminardetalle($detallepedido: DetallePedido) {
  //   let _ban = true;
  //   if ($detallepedido.id > 0) {
  //     let data = {
  //       iddetalle: $detallepedido.id,
  //     };
  //     console.log(JSON.stringify(data));
  //     await axios
  //       .post(this.constants.apiUrl + "eliminar_itemdetalle", data, {
  //         headers: this.constants.headers_aut,
  //       })
  //       .then((response) => {
  //         if (response.data.estado) {
  //           _ban = true;
  //         } else {
  //           _ban = false;
  //           swal("Detalle de pedido ", response.data.message, "info");
  //         }
  //       })
  //       .catch(function (error) {
  //         _ban = false;
  //         swal("Ha ocurrido un error al registrar!", error.message, "error");
  //         console.log("tipo error " + error);
  //       });
  //   }
  //   if (_ban) {
  //     let ind: number = -1;
  //     this.prodpedido_lst.forEach((item, index) => {
  //       if (item.idProducto === $detallepedido.idProducto) ind = index;
  //     });
  //     if (ind > -1) {
  //       this.prodpedido_lst.splice(ind);
  //       swal(
  //         "Detalle de pedido ",
  //         "Se eliminó el producto seleccionado de la lista.",
  //         "success"
  //       ).then(async () => {
  //         // ACTUALIZAR REGISTRO PEDIDO
  //         let detpedido: Array<DetallePedido>;
  //         detpedido = new Array<DetallePedido>();
  //         console.log(this.prodpedido_lst);
  //         this.prodpedido_lst.forEach((item) => {
  //           if (item.id === 0) {
  //             detpedido.push(
  //               new DetallePedido(
  //                 item.id,
  //                 this.pedido.id,
  //                 item.idProducto,
  //                 item.cantidad,
  //                 item.cantidadAtendida,
  //                 item.cantidadRecibida,
  //                 item.cantidadRecibida,
  //                 item.cantidadFaltante,
  //                 item.precioUnit,
  //                 item.precioTotal,
  //                 item.vigencia,
  //                 item.producto,
  //                 item.precioAtendido,
  //                 0,
  //                 0,
  //                 0
  //               )
  //             );
  //           }
  //         });
  //         let tienda_aux: Tienda = JSON.parse(
  //           localStorage.getItem("datostienda_tda")
  //         );
  //         let data = {
  //           id: this.pedido.id,
  //           idTienda: tienda_aux.tda_id,
  //           idEmpresa: tienda_aux.tda_idNegocio,
  //           direccion:
  //             tienda_aux.tda_direccion +
  //             " - " +
  //             tienda_aux.tda_ciudad +
  //             " \nReferencia: " +
  //             tienda_aux.tda_referencia,
  //           idProveedor: this.proveddor_reg.org_id,
  //           fechaPedido:
  //             this.fechaventa["year"] +
  //             "-" +
  //             this.fechaventa["month"] +
  //             "-" +
  //             this.fechaventa["day"],
  //           estado: 0,
  //           beneficio: false,
  //           total: this.getTotalCost(),
  //           subTotal: this.getSubTotalCost(),
  //           igv: this.getIgvCost(),
  //           detallepedido: this.prodpedido_lst,
  //         };
  //         console.log(JSON.stringify(data));
  //         await axios
  //           .post(this.constants.apiUrl + "registro_pedido", data, {
  //             headers: this.constants.headers_aut,
  //           })
  //           .then((response) => {
  //             console.log(response.data);
  //             this.spinner.hide();
  //             if (response.data.estado) {
  //               console.log("Se actualizó el pedido");
  //             } else {
  //               swal("Registro pedido", response.data.mensaje, "info");
  //             }
  //           })
  //           .catch(function (error) {
  //             swal(
  //               "Ha ocurrido un error al registrar!",
  //               error.message,
  //               "error"
  //             );
  //             console.log("tipo error " + error);
  //           });
  //         if (this.prodpedido_lst.length == 0) {
  //           this._showeditdet = false;
  //           this._showedit = false;
  //         }
  //       });
  //     }
  //   }
  // }

  cancelar(estado: boolean) {
    // true: registro / false:listado
    swal({
      title: estado
        ? "¿Desea cancelar la operación?"
        : "¿Desea ir a realizar pedido?",
      text: "Registro de pedidos",
      icon: "info",
      buttons: ["Cancelar", true],
    }).then((willDelete) => {
      if (willDelete) {
        this.limpiar_operacion(estado);
      }
    });
  }

  limpiar_operacion(estado: boolean) {
    if (estado) {
      if (this._show) {
        this._showdetalle = true;
        this._showdlista = false;
      } else {
        this._showdetalle = false;
        this._showdlista = true;
      }
    } else {
      this._show = true;
      this._showdetalle = true;
    }
    this.prodpedido_lst.length > 0 ? (this._show = false) : (this._show = true);
    this._showedit = false;
    this._showeditdet = false;
    this.prodpedido_lst = new Array<DetallePedido>();
    this._cantprod = 0;
  }

  verlista() {
    this._show = false;
    this._showdetalle = false;
    this._showdlista = true;
    this._cart = true;
    this.listar();
  }

  agregar() {
    let _ban = true;
    let prod_cantidad = this.cantidadprod;
    this.prodpedido_lst.forEach((item) => {
      if (item.idProducto == this.modelproducto.prod_id) {
        _ban = false;
      }
    });
    if (_ban) {
      if (prod_cantidad > 0) {
        this.modelproducto.prod_preciototal = (
          prod_cantidad * this.modelproducto.prod_precio
        ).toFixed(2);

        this.prodpedido_lst.push(
          new DetallePedido(
            0,
            0,
            this.modelproducto.prod_id,
            parseFloat(prod_cantidad).toFixed(2),
            0,
            0,
            0,
            parseFloat(prod_cantidad).toFixed(2),
            parseFloat(this.modelproducto.prod_precio).toFixed(2),
            parseFloat(
              (prod_cantidad * this.modelproducto.prod_precio).toString()
            ).toFixed(2),
            true,
            this.modelproducto.prod_descripcion,
            0,
            0,
            0, this.modelproducto.prod_adminactualiza ,
            parseFloat(this.modelproducto.prod_cantidad).toFixed(2)
          )
        );
        this.dataSource = this.prodpedido_lst;
      } else {
        swal(
          "Registro de pedidos",
          "Ingrese cantidad para " + this.modelproducto.prod_descripcion,
          "info"
        );
      }
      if (this.prodpedido_lst.length > 0) {
        this._showedit = true;
      }
    } else {
      swal(
        "Registro de pedidos",
        "Producto " +
          this.modelproducto.prod_descripcion +
          " ya ha sido agregado.",
        "info"
      );
    }
    if (this.prodpedido_lst.length == 1) {
      this._cantprod = this.proveddor_reg.org_id;
    }

    this.modelproducto = null;
    this.cantidadprod = 0;
  }

  getTotalCost() {
    // return parseFloat(
    //   this.prodpedido_lst
    //     .map((t) => t.prod_preciototal)
    //     .reduce((acc, value) => acc + value, 0)
    // )

    return this.prodpedido_lst
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioTotal) == "number"
            ? sum + parseFloat(value.precioTotal)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalCost() {
    let total = this.getTotalCost();
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvCost() {
    let total = this.getTotalCost();
    return (
      parseFloat(this.getTotalCost()) - parseFloat(this.getSubTotalCost())
    ).toFixed(2);
  }

  getTotalCostProv($id: number) {
    return this.prodpedido_lst
      .filter((ped) => ped.idProveedor == $id)
      .reduce(
        (sum, value) =>
          typeof parseFloat(value.precioTotal) == "number"
            ? sum + parseFloat(value.precioTotal)
            : sum,
        0
      )
      .toFixed(2);
  }

  getSubTotalCostProv($id: number) {
    let total = this.getTotalCostProv($id);
    return (parseFloat(total) / 1.18).toFixed(2);
  }

  getIgvCostProv($id: number) {
    let total = this.getTotalCostProv($id);
    return (
      parseFloat(this.getTotalCostProv($id)) -
      parseFloat(this.getSubTotalCostProv($id))
    ).toFixed(2);
  }

  seleccionar_proveedor($id: number, $idcategoria: number, $turno:string) {

      this.constants.listado_productos_filtro($id, $idcategoria, $turno);
  }

  async listar_proveedor() {
    this.spinner.show();
    console.log("USUARIO " + JSON.stringify(this.constants.usuario_tda));
    let request = {
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_proveedor_vigente");
    await axios
      .post(this.constants.apiUrl + "lista_proveedor_vigente", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        this.proveedor_lst = new Array<Organizacion>();
        let organizacion_auxlst = response.data.data;
        if (response.data.estado) {
          if (organizacion_auxlst.length > 0) {
            this.proveedor_lst.push(
              new Organizacion(
                0,
                "TODOS",
                "",
                "",
                "",
                "",
                "",
                0,
                "",
                0,
                "",
                "",
                "",
                false,
                false,
                false,
                false,
                "",
                "",
                false,
                null,
                0,
                null,
                0
              )
            );
            organizacion_auxlst.forEach((item) => {
              this.proveedor_lst.push(
                new Organizacion(
                  item.id,
                  item.empresa,
                  item.tipodocumento,
                  item.documento,
                  item.representante,
                  item.email,
                  item.telefono,
                  item.negocio,
                  item.otrosector,
                  item.sector,
                  item.pais,
                  item.ciudad,
                  item.codigopostal == null || item.codigopostal == "null"
                    ? "00000"
                    : item.codigopostal,
                  true,
                  false,
                  false,
                  item.vigencia,
                  item.mensaje,
                  "",
                  null,
                  null,
                  null,
                  null,
                  null
                )
              );
            });
          }
        } else {
          swal("Datos de organización", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }

  // NUEVAS FUNCIONES
  edit_detail($state: boolean) {
    if (!$state) {
      this.prodpedido_lst.forEach((item) => {
        item.cantidad = parseFloat(item.cantidad).toFixed(2);
        item.precioTotal = (item.cantidad * item.precioUnit).toFixed(2);
      });
    }
    this._showeditdet = $state;
    this.constants.pedido_cart = this.prodpedido_lst;
    this.constants.prov_cart = this.prov_ped;
  }
  ircarrito() {
    if (this.prodpedido_lst.length == 0) {
      this._cart = false;
      this.typealert = "danger";
      this._success.next("No hay productos agregados al carrito.");
    } else {
      this._cart = true;
      this._showeditdet = false;
      this._showdetalle = true;
    }
  }

  lista_prod() {
    this._cart = false;
  }

  agregar_prod($prod: Producto) {
    if (this.prodpedido_lst.length == 0) {
      this.constants.pedido_cart = new Array<DetallePedido>();
      this.constants.prov_cart = [];
    }
    let _ban = true;
    let cantidad: any = $prod.prod_cantpedido;
    this.modelproducto = $prod;
    this.prodpedido_lst.forEach((item) => {
      if (item.idProducto == this.modelproducto.prod_id) {
        _ban = false;
        cantidad = parseFloat(item.cantidad) + parseFloat(cantidad);
        item.precioTotal = (cantidad * $prod.prod_precio).toFixed(2);

        item.cantidad = parseFloat(cantidad).toFixed(2);
        item.cantidadFaltante = cantidad;
      }
    });
    if (_ban) {
      this.modelproducto.prod_preciototal = (
        cantidad * $prod.prod_precio
      ).toFixed(2);

      this.prodpedido_lst.push(
        new DetallePedido(
          0,
          0,
          this.modelproducto.prod_id,
          parseFloat(cantidad).toFixed(2),
          0,
          0,
          0,
          parseFloat(cantidad).toFixed(2),
          parseFloat(this.modelproducto.prod_precio).toFixed(2),
          parseFloat(
            (cantidad * this.modelproducto.prod_precio).toString()
          ).toFixed(2),
          true,
          this.modelproducto.prod_descripcion,
          0,
          0,
          this.modelproducto.prod_idProveedor, this.modelproducto.prod_adminactualiza,
          parseFloat(this.modelproducto.prod_cantidad).toFixed(2)
        )
      );

      if (this.prov_ped.length == 0) {
        this.prov_ped.push(this.modelproducto.prod_idProveedor);
      } else {
        if (!this.prov_ped.includes(this.modelproducto.prod_idProveedor)) {
          this.prov_ped.push(this.modelproducto.prod_idProveedor);
        }
      }
      this.constants.pedido_cart = this.prodpedido_lst;
      this.constants.prov_cart = this.prov_ped;
      console.log("PROVEDOR" + JSON.stringify(this.prov_ped));
    }
    this.typealert = "success";
    this._success.next(
      "Se agregó a la lista " +
        this.modelproducto.prod_descripcion +
        "(" +
        cantidad +
        ")"
    );
  }

  async eliminardetalle($detallepedido: DetallePedido) {
    let index: number = -1;
    let index2: number = -1;
    let idProv: number = 0;
    let c_prov: number = 0;
    console.log(JSON.stringify(this.prodpedido_lst));
    console.log($detallepedido);
    index = this.prodpedido_lst.indexOf($detallepedido);
    if (index > -1) {
      // this.constants.pedido_cart.splice(index, 1);
      this.prodpedido_lst.splice(index, 1);
      idProv = $detallepedido.idProveedor;
      this.prodpedido_lst.forEach((item) => {
        if (item.idProveedor == idProv) {
          c_prov = c_prov + 1;
        }
      });
      this.typealert = "danger";
      this._success.next("Se eliminó el producto " + $detallepedido.producto);
      if (c_prov == 0) {
        index2 = this.prov_ped.indexOf(idProv);
        this.prov_ped.splice(index2, 1);
        // this.constants.prov_cart.splice(index2, 1);
      }
    }
    this.constants.pedido_cart = this.prodpedido_lst;
    this.constants.prov_cart = this.prov_ped;
    if (this.prodpedido_lst.length == 0) {
      setTimeout(() => {
        this.typealert = "danger";
        this._success.next("Agregar productos al carrito");
        this._cart = false;
      }, 5000);
    }
    console.log("IND DETALLE PEDIDO " + index);

    console.log("PROVEEDORES DE CARRITOS " + JSON.stringify(this.prov_ped));
  }

  open(content, $pedido: Pedido) {
    this.pedido = $pedido;
    this.detpedido_lst = new Array<DetallePedido>();
    this.pedido.total = parseFloat($pedido.total).toFixed(2);
    this.pedido.subtotal = parseFloat($pedido.subtotal).toFixed(2);
    this.pedido.igv = parseFloat($pedido.igv).toFixed(2);

    this.listarpedidodetalle($pedido.id);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async listarpedidodetalle($idpedido: number) {
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idpedido: $idpedido,
      token: this.constants.usuario_tda.us_token,
    };
    await axios
      .post(this.constants.apiUrl + "lista_detallepedido", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let detalle_lst_aux = response.data.data;
        this.prodpedido_lst = new Array<DetallePedido>();
        if (response.data.estado) {
          for (let element in detalle_lst_aux) {
            this.detpedido_lst.push(detalle_lst_aux[element]);
          }
        } else {
          swal("Detalle de pedido ", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal(
          "Ha ocurrido un error al listar detalle de pedido!",
          error.message,
          "error"
        );
        console.log("tipo error " + error);
      });
  }

  validarturno(){
    this.prod_idProveedor = 0
    this.prod_idSubCategoria = 0
    this.prod_idCategoria = 0
    if(this.prod_turno != "TODOS"){
      this.dis = true
    }else{
      this.dis = false
    }
  }

  dataForExcel = [];
   empPerformance = []

  ExportToExcel()
  {
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table_exp.nativeElement);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // /* save to file */
    // XLSX.writeFile(wb, 'cierrecaja.xlsx');
    this.empPerformance = [];
    this.lstpedido.forEach((item) => {
      this.empPerformance.push({ "FECHA":item.fechaPedido.toString().substr(0,10), "ESTADO":item.descestado,"PROVEEDOR":item.proveedor, "MONTO": parseFloat(item.total).toFixed(2)})
    });
    this.empPerformance.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Pedidos realizados',
      data: this.dataForExcel,
      headers: Object.keys(this.empPerformance[0]),
      description: "Reportes de pedidos realizados descargado el día"
    }

    this.ete.exportExcel(reportData);


  }


  async ExportToPDF($id){
    window.open(
      this.constants.apiUrl +
        "descargar-pedido?idPedido=" +
        $id,
      "_blank"
    );

  }

  async ExportExcel(){
    let data: data4col[] = []
    data.push({data1:'FECHA',data2:'ESTADO', data3:'PROVEEDOR', data4:'MONTO'})
    this.lstpedido.forEach((item) => {
      data.push({data1:item.fechaPedido.toString().substr(0,10),data2:item.descestado,data3:item.proveedor,data4:parseFloat(item.total).toFixed(2)})
    });
    window.open(
      this.constants.apiUrl +
        "excel-reporte?data=" +
        JSON.stringify(data) + "&nombre=Pedidos realizados&col=4",
      "_blank"
    );
  }
}
