import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Perfil } from "src/app/models/perfil";
import { Tienda } from "src/app/models/tienda";
import { Inventario } from "src/app/models/inventario";
import { ExportExcelService } from "src/app/services/export-excel.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { data4col } from "src/app/models/exportexcel";
const swal: SweetAlert = _swal as any;

@Component({
  selector: "reginventario",
  templateUrl: "./reginventario.component.html",
})
export class InventarioComponent implements OnInit {
  public title: string;
  public rute: string;
  public inventario_lst: Array<Inventario>;
  public inventario_fil: Array<Inventario>;
  public searchinput: string = "";
  public tipo: number = 0;
  public movproducto:Array<Inventario>;
  public closeResult = "";
  public prod_desc:String = "";
  public prod_categ:String = "";
  public prod_prov:String = "";
  public prod_stock:any = ""
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    public ete: ExportExcelService,
    private modalService: NgbModal,
  ) {
    this.title = "Inventario";
    this.rute = "tda/inventario";
    this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
  }

  ngOnInit() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.inventario_lst = new Array<Inventario>();
    this.inventario_fil = new Array<Inventario>();
    this.listainventario();
  }

  async listainventario() {
    this.inventario_lst = new Array<Inventario>();
    this.inventario_fil = new Array<Inventario>();
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    let request = {
      idtienda: tiendaaux.tda_id,
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "lista_inventario", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let inventario_lst_aux = response.data.data;

        if (response.data.estado) {
          if (inventario_lst_aux != null) {
            for (let element in inventario_lst_aux) {
              //console.log("num " + element);

              this.inventario_lst.push(inventario_lst_aux[element]);
            }
            this.inventario_fil = this.inventario_lst
          }
        } else {
          swal("Inventario", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error!", error.message, "error");
        console.log(error);
      });
  }

  search_inventario($txt: string) {
    $txt = $txt.toLocaleLowerCase();
      this.inventario_lst = new Array<Inventario>();
    if (this.tipo == 0) {
      this.inventario_lst = this.inventario_fil.filter(
        (producto: Inventario) =>
          producto.producto.toLocaleLowerCase().indexOf($txt) !== -1
      );
    }else if (this.tipo == 1) {
      this.inventario_lst = this.inventario_fil.filter(
        (producto: Inventario) =>
          producto.categoria.toLocaleLowerCase().indexOf($txt) !== -1
      );
    }
  }

  public lista_inventario_refresh() {
    this.searchinput = "";
    this.search_inventario("");
  }

  async vermovimientos(content, $inventario:Inventario){
    this.movproducto = new Array<Inventario>()
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.prod_desc = $inventario.producto
    this.prod_categ = $inventario.categoria
    this.prod_prov = $inventario.proveedor
    this.prod_stock = parseFloat($inventario.stock).toFixed(2)
    let request = {
      idTienda: tiendaaux.tda_id,
      idProveedor: $inventario.idProveedor,
      idProducto : $inventario.idProducto
    };
    console.log(JSON.stringify(request));
    await axios
      .post(this.constants.apiUrl + "movimientos-producto", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let inv = response.data.data;

        if (response.data.estado) {
          if (inv != null) {
            for (let element in inv) {
              //console.log("num " + element);

              this.movproducto.push(new Inventario(0, parseFloat(inv[element].cantidad).toFixed(2), 0.0,0,0,0,0,inv[element].fecha,0,null,"","","",inv[element].movimiento,""

              ));
            }
          }
          console.log(JSON.stringify(this.movproducto));
          this.modalService
            .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
            .result.then(
              (result) => {
                this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
        } else {
          swal("Inventario", response.data.message, "info");
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error!", error.message, "error");
        console.log(error);
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  dataForExcel = [];
   empPerformance = []

  ExportToExcel()
  {
    this.empPerformance = [];
    this.inventario_lst.forEach((item) => {
      this.empPerformance.push({ "CATEGORIA":item.categoria.toString(), "PROVEEDOR":item.proveedor,"PRODUCTO":item.producto, "STOCK": item.stock})
    });
    this.empPerformance.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })
    let d = new Date();
    let date = d.getDate().toString().padStart(2,"0") + '-' + (d.getMonth() + 1).toString().padStart(2,"0") + '-' + d.getFullYear();
    let reportData = {
      title: 'Inventario al día ' + date,
      data: this.dataForExcel,
      headers: Object.keys(this.empPerformance[0]),
      description: "Inventario descargado el día "
    }

    this.ete.exportExcel(reportData);


  }

  async ExportExcel(){
    let data: data4col[] = []
    data.push({data1:'CATEGORÍA',data2:'PROVEEDOR', data3:'PRODUCTO', data4:'STOCK'})
    this.inventario_lst.forEach((item) => {
      data.push({data1:item.categoria.toString(), data2:item.proveedor, data3: item.producto, data4: item.stock})
    });
    let d = new Date();
    let date = d.getDate().toString().padStart(2,"0") + '-' + (d.getMonth() + 1).toString().padStart(2,"0") + '-' + d.getFullYear()
    let title= 'Inventario al día ' + date.toString();
    window.open(
      this.constants.apiUrl +
        "excel-reporte?data=" +
        JSON.stringify(data) + "&nombre="+title+"&col=4",
      "_blank"
    );
  }
}
