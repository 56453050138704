import { Component, OnInit, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ConstantsService } from "../../../constants.service";
import { NgxSpinnerService } from "ngx-spinner";
import axios from "axios";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
import { Perfil } from "src/app/models/perfil";
import { Tienda } from "src/app/models/tienda";
const swal: SweetAlert = _swal as any;

import {
  NgbDateStruct,
  NgbTypeaheadConfig,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbModal, ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { isNumber, isDate } from "util";

import {
  debounceTime,
  distinctUntilChanged,
  map,
  filter,
} from "rxjs/operators";

import { element } from "protractor";
import { DetalleOrden, Orden } from "src/app/models/orden";

@Component({
  selector: "regdevolucion",
  templateUrl: "./regdevolucion.component.html",
  providers: [NgbTypeaheadConfig],
  styles: [
    `
      .form-group.hidden {
        width: 0;
        margin: 0;
        border: none;
        padding: 0;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
      .custom-day.focused {
        background-color: #e6e6e6;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
      }
      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
      .modal-dialog{max-width:100% !important}
    `,

  ],
})
export class RegDevolucionComponent implements OnInit {
  public title: string;
  public vr_imp: boolean = false;
  public rute: string;
  public orden_lst: Array<Orden>;
  public orden_fil: Array<Orden>;
  public detorden_lst: Array<DetalleOrden>;
  public orden_reg: Orden;
  public closeResult = "";
  public deuda: any;
  // public _numlst_lg: string = "12";
  // public _numlst_md: string = "12";
  fechafin:any;
  fechainicio:any;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  hoveredDate: NgbDate;
  public _model: any;
  public _comstants_producto: any;
  public modelproducto: any;

  model: NgbDateStruct;
  placement = "left";
  constructor(
    // private _router : ActivatedRoute,
    public router: Router,
    public constants: ConstantsService,
    public spinner: NgxSpinnerService,
    config: NgbTypeaheadConfig,
    private el: ElementRef,
    private calendar: NgbCalendar,
    public formatterdt: NgbDateParserFormatter,
    private modalService: NgbModal
  ) {
    this.title = "Devoluciones";
    this.rute = "tda/devolucion";
    this.constants.permisotda_existe(this.rute);
    this.constants.sesiontda(this.rute);
    this.orden_lst = new Array<Orden>();
    this.orden_fil = new Array<Orden>();
  }

  ngOnInit() {
    this.constants.usuario_tda = JSON.parse(
      localStorage.getItem("logindatos_tda")
    );
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), "d", -10);
    this.fechainicio = this.fromDate.year + "-" + this.fromDate.month.toString().padStart(2, "0") + "-" + this.fromDate.day.toString().padStart(2, "0")
    this.fechafin =  this.toDate.year + "-" + this.toDate.month.toString().padStart(2, "0") + "-" + this.toDate.day.toString().padStart(2, "0")
    this.listar();
  }

  formatter = (producto: any) => producto._nombremodelo;

  searchprod = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === ""
          ? []
          : this._comstants_producto //this.constants.productos
              .filter((producto) =>
                new RegExp(term, "mi").test(producto._nombremodelo)
              )
              .slice(0, 10)
      )
    );

  async listar() {
    this.spinner.show();
    let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
    let request = {
      idtienda: tiendaaux.tda_id,
      fechainicio:
        this.fromDate.year.toString() +
        "-" +
        this.fromDate.month.toString().padStart(2, "00") +
        "-" +
        this.fromDate.day.toString().padStart(2, "00"),
        fechafin:
        this.toDate.year.toString() +
        "-" +
        this.toDate.month.toString().padStart(2, "00") +
        "-" +
        this.toDate.day.toString().padStart(2, "00"),
      token: this.constants.usuario_tda.us_token,
    };
    console.log(JSON.stringify(request));
    console.log(this.constants.apiUrl + "lista_devoluciones");
    this.orden_lst = new Array<Orden>();
    this.orden_fil = new Array<Orden>();
    await axios
      .post(this.constants.apiUrl + "lista_devoluciones", request, {
        headers: this.constants.headers_aut,
      })
      .then((response) => {
        console.log(response.data);
        this.spinner.hide();
        let pedido_lst_aux = response.data.data;
        if (response.data.estado) {
          if (response.data.data != null || response.data.data != "null") {
            this.orden_lst = response.data.data;
            this.orden_lst.forEach((item) => {
              item.total = parseFloat(item.total).toFixed(2);
              item.montodevuelto = parseFloat(item.montodevuelto).toFixed(2);
              item.cliente = item.idCliente ==  0 ? 'OTROS' : item.cliente;
              if (item.total == item.montodevuelto) {
                item.tipoPago = 3;
              }
            });
            this.orden_fil = response.data.data;
          }
        }
      })
      .catch(function (error) {
        this.spinner.hide();
        swal("Ha ocurrido un error al registrar!", error.message, "error");
        console.log("tipo error " + error);
      });
  }



  //FECHAS
  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatterdt.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  verdetalle(content,$datos_ord:Orden){
      this.detorden_lst = new Array<DetalleOrden>();
      this.orden_reg = $datos_ord;
      this.orden_reg.montopago = parseFloat($datos_ord.montopago).toFixed(2);
      this.orden_reg.total = parseFloat($datos_ord.total).toFixed(2);
      this.orden_reg.montodevuelto = parseFloat($datos_ord.montodevuelto).toFixed(2);
      let totalamort: number =
        parseFloat($datos_ord.montopago) + parseFloat($datos_ord.montodevuelto);
      this.deuda = ($datos_ord.total - totalamort).toFixed(2);
      this.listardetalleorden($datos_ord.id);
      this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title", windowClass: "modal-dialog-front" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return "by pressing ESC";
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return "by clicking on a backdrop";
      } else {
        return `with: ${reason}`;
      }
    }

    async listardetalleorden($idorden: number) {
      // this._showdlista = false;
      // this._showdetalle = true;
      // this._showedit = true;
      this.spinner.show();
      let tiendaaux: Tienda = JSON.parse(localStorage.getItem("datostienda_tda"));
      let request = {
        idorden: $idorden,
        token: this.constants.usuario_tda.us_token,
      };
      console.log(JSON.stringify(request));
      console.log(this.constants.apiUrl + "lista_detalleorden");
      await axios
        .post(this.constants.apiUrl + "lista_detalleorden", request, {
          headers: this.constants.headers_aut,
        })
        .then((response) => {
          console.log(response.data);
          this.spinner.hide();
          let detalle_lst_aux = response.data.data;
          this.detorden_lst = new Array<DetalleOrden>();
          if (response.data.estado) {
            this.detorden_lst = detalle_lst_aux;
            this.detorden_lst.forEach((item) => {
              item.cantidad = parseFloat(item.cantidad).toFixed(2);
              item.cantidaddev = parseFloat(item.cantidaddev).toFixed(2);
              item.cantidaddev_act = parseFloat(item.cantidaddev_act).toFixed(2);
              item.precioDevolver = parseFloat(item.precioDevolver).toFixed(2);
              item.precioUnit = parseFloat(item.precioUnit).toFixed(2);
              item.precioVentaUnid = parseFloat(item.precioVentaUnid).toFixed(2);
              item.precioTotal = parseFloat(item.precioTotal).toFixed(2);
            });
          } else {
            swal("Detalle de venta ", response.data.message, "info");
          }
        })
        .catch(function (error) {
          this.spinner.hide();
          swal(
            "Ha ocurrido un error al listar detalle de venta!",
            error.message,
            "error"
          );
          console.log("tipo error " + error);
        });
    }

    getTotalOrd() {
      return this.detorden_lst
        .reduce(
          (sum, value) =>
            typeof parseFloat(value.precioTotal) == "number"
              ? sum + parseFloat(value.precioTotal)
              : sum,
          0
        )
        .toFixed(2);
    }

    getSubTotalOrd() {
      let total = this.getTotalOrd();
      return (parseFloat(total) / 1.18).toFixed(2);
    }

    getIgvOrd() {
      let total = this.getTotalOrd();
      return (
        parseFloat(this.getTotalOrd()) - parseFloat(this.getSubTotalOrd())
      ).toFixed(2);
    }
}
