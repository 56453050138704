export class Organizacion {
  constructor(
    public org_id: number,
    public org_empresa: string,
    public org_tipodocumento: string,
    public org_documento: string,
    public org_representante: string,
    public org_email: string,
    public org_telefono: string,
    public org_negocio: number,
    public org_otrosector: string,
    public org_sector: number,
    public org_pais: string,
    public org_ciudad: string,
    public org_codigopostal: string,
    public org_proveedor: boolean,
    public org_auspiciador: boolean,
    public org_patrocinador: boolean,
    public org_vigencia: boolean,
    public org_mensaje: string,
    public org_codigoconfirmacion: string,
    public org_usuariocreado: boolean,
    public org_fechavalidacion: Date,
    public org_adminvalidacion: number,
    public org_fechaactualiza: Date,
    public org_adminactualiza: number
  ) {}
}

export class HorarioEntrega {
  constructor(
    public empresa: string,
    public horainicio: string,
    public horafin: string,
    public turno: string,
    public departamento: string,
    public provincia: string,
    public distrito: string
  ) {}
}

export class proveedorcbo{
  constructor(
    public id: number,
    public empresa: string,
  ){}
}
